import FeedbackSend from './graphql/FeedbackSend.gql'
import TeamQuery from './graphql/TeamQuery.gql'
import TeamCreateMutation from './graphql/TeamCreateMutation.gql'
import ScopesQuery from './graphql/ScopesQuery.gql'
import TeamMembershipMutation from './graphql/TeamMembershipMutation.gql'
import TeamAttributeMutation from './graphql/TeamAttributeMutation.gql'
import TeamEventSubscription from './graphql/TeamEventSubscription.gql'
import UsersQuery from './graphql/UsersQuery.gql'
import User from '../models/User'
import UserInvite from './graphql/UserInvite.gql'
import UserCreateMutation from './graphql/UserCreateMutation.gql'
import UserEditMutation from './graphql/UserEditMutation.gql'
import UserRemove from './graphql/UserRemove.gql'
import SetupSandboxUser from './graphql/SetupSandboxUser.gql'
import plugin from '../vue-plugins/authz'

const TEAM_RESOURCE = 'urn:frontdesk:team'

export default {
  namespaced: true,
  state: {
    teams: null
  },
  wrapActions: {
    'core/initApp': (action, store) => async app => {
      app.Vue.use(plugin)
      const authenticated = await action(app)
      if (!authenticated) {
        return authenticated
      }

      let resolveFirstTeamsPayload
      const firstTeamsPayloadPromise = new Promise(resolve => {
        resolveFirstTeamsPayload = () => {
          resolve()
          resolveFirstTeamsPayload = () => {}
        }
      })

      store.$graphql.subscribe({ query: TeamEventSubscription }).subscribe(
        ({ data: { teamEvent: { topic, teams } } }) => {
          resolveFirstTeamsPayload()
          store.commit('core/authz/teams', teams)
        }
      )

      await firstTeamsPayloadPromise

      return action(app)
    }
  },
  mutations: {
    teams (state, teams) {
      state.teams = teams
    }
  },
  actions: {
    getTeamDetails (store, name) {
      return this.$graphql.query({ query: TeamQuery, fetchPolicy: 'no-cache', variables: { name } })
        .then(response => response.data.team)
    },
    createTeam ({ dispatch }, team) {
      this.$graphql.mutate({
        mutation: TeamCreateMutation,
        variables: team
      })
    },
    createUser ({ dispatch }, user) {
      return this.$graphql.mutate({
        mutation: UserCreateMutation,
        variables: user
      })
    },
    editUser ({ dispatch }, user) {
      return this.$graphql.mutate({
        mutation: UserEditMutation,
        variables: user
      })
    },
    sendFeedback ({ dispatch }, feedback) {
      return this.$graphql.mutate({ mutation: FeedbackSend, variables: feedback })
    },
    // atm this will only force user to update password
    inviteUser ({ dispatch }, userId) {
      return this.$graphql.mutate({ mutation: UserInvite, variables: userId })
    },
    removeUser ({ dispatch }, userId) {
      return this.$graphql.mutate({ mutation: UserRemove, variables: userId })
    },

    getUsers (store, params) {
      return this.$graphql.query({ query: UsersQuery, variables: params, fetchPolicy: 'no-cache' })
        .then(response => response.data.users.map(user => new User(user)))
    },
    getTeamScopes (store) {
      return this.$graphql.query({ query: ScopesQuery, variables: { resource: TEAM_RESOURCE } })
        .then(response => response.data.scopes)
    },
    setTeamMembership (store, params) {
      return this.$graphql.mutate({ mutation: TeamMembershipMutation, variables: params })
    },
    setTeamAttribute (store, attribute) {
      return this.$graphql.mutate({ mutation: TeamAttributeMutation, variables: attribute }).then(
        response => response.data.team.attributes
      )
    },
    setupSandboxUser (store, params) {
      return this.$graphql.mutate({ mutation: SetupSandboxUser, variables: params })
    }
  }
}
