<template>
  <div class="sidebar">
    <SidebarHeader />
    <SidebarForm />
    <nav class="sidebar-nav">
      <div slot="header" />
      <ul class="nav">
        <template v-for="(item, index) in filteredItems">
          <template v-if="item.title">
            <SidebarNavTitle
              :key="'filtered-sidebar-items-' + index"
              :name="item.name"
              :classes="item.class"
              :wrapper="item.wrapper"
            />
          </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider
            :key="'sidebar-divider-' + index"
            :classes="item.class" />
          </template>
          <template v-else>
            <template v-if="item.children && item.children.length">
              <!-- First level dropdown -->
              <SidebarNavDropdown
                :key="'sidebar-dropdown-' + index"
                :name="item.name"
                :url="item.url"
                :icon="item.icon"
              >
                <template v-for="(childL1, index) in item.children">
                  <template v-if="childL1.children && childL1.children.length">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown
                      :key="'sidebar-child-dropdown-' + index"
                      :name="childL1.name"
                      :url="childL1.url"
                      :icon="childL1.icon"
                    >
                      <li
                        :key="'sidebar-child2-'+ index"
                        v-for="(childL2, index) in childL1.children"
                        class="nav-item"
                      >
                        <SidebarNavLink
                          :name="childL2.name"
                          :url="childL2.url"
                          :icon="childL2.icon"
                          :badge="childL2.badge"
                          :variant="item.variant"
                        />
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem
                      :key="'sidebar-navitem-' + index"
                      :classes="item.class">
                      <SidebarNavLink
                        :name="childL1.name"
                        :url="childL1.url"
                        :icon="childL1.icon"
                        :badge="childL1.badge"
                        :variant="item.variant"
                      />
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem
                :key="'sidebar-navitem-' + index"
                :classes="item.class">
                <SidebarNavLink
                  v-track:click="{action: 'go to ' + item.url, category: 'sidebar'}"
                  :name="item.name"
                  :url="item.url"
                  :icon="item.icon"
                  :badge="item.badge"
                  :external="item.external"
                  :variant="item.variant"
                />
              </SidebarNavItem>
            </template>
          </template>
        </template>
      </ul>
      <slot />
    </nav>
    <SidebarFooter />
    <SidebarMinimizer />
  </div>
</template>
<script>
  import SidebarFooter from './SidebarFooter'
  import SidebarForm from './SidebarForm'
  import SidebarHeader from './SidebarHeader'
  import SidebarMinimizer from './SidebarMinimizer'
  import SidebarNavDivider from './SidebarNavDivider'
  import SidebarNavDropdown from './SidebarNavDropdown'
  import SidebarNavLink from './SidebarNavLink'
  import SidebarNavTitle from './SidebarNavTitle'
  import SidebarNavItem from './SidebarNavItem'
  export default {
    name: 'Sidebar',
    components: {
      SidebarFooter,
      SidebarForm,
      SidebarHeader,
      SidebarMinimizer,
      SidebarNavDivider,
      SidebarNavDropdown,
      SidebarNavLink,
      SidebarNavTitle,
      SidebarNavItem
    },
    props: {
      navItems: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    computed: {
      filteredItems () {
        const items = []
        let last
        const filtered = this.navItems.filter(item => !item.condition || item.condition.call(this))
        const max = filtered.length - 1
        filtered.forEach((item, i) => {
          if (item.divider && (!last || last.divider || i === max)) {
            // Ignore leading, trailing and double dividers
            return
          }
          if (item.title && (i === max || (filtered[i + 1].title || filtered[i + 1].divider))) {
            // Ignore titles without subsequent items
            return
          }
          const newItem = { ...item }
          if (item.children) {
            newItem.children = item.children.filter(item => !item.condition || item.condition.call(this))
          }
          items.push(newItem)
          last = newItem
        })
        return items
      },
      componentName () {
        return this.$options._componentTag
      }
    },
    methods: {
      handleClick (e) {
        e.preventDefault()
        e.target.parentElement.classList.toggle('open')
      }
    }
  }
</script>

<style lang="css">
  .nav-link {
    cursor:pointer;
  }
</style>
