import { StringUtil } from '@/util'
import ApplicationsQuery from './graphql/ApplicationsQuery.gql'
import ApplicationDetailsQuery from './graphql/ApplicationDetailsQuery.gql'
import ApplicationLogsQuery from './graphql/ApplicationLogsQuery.gql'
import ApplicationSaveMutation from './graphql/ApplicationSaveMutation.gql'
import ApplicationUpdateSubscription from './graphql/ApplicationUpdateSubscription.gql'
import ApplicationTriggerMutation from './graphql/ApplicationTriggerMutation.gql'
import ApplicationTriggersQuery from './graphql/ApplicationTriggersQuery.gql'
import ApplicationDeleteMutation from './graphql/ApplicationDeleteMutation.gql'

export default {
  namespaced: true,
  wrapActions: {
    'core/initApp': (action, store) => async payload => {
      const authenticated = await action(payload)
      if (!authenticated) {
        return authenticated
      }
      store.$graphql.subscribe({ query: ApplicationUpdateSubscription }).subscribe(({ data }) => {
        const { sadminApplicationEvent: event } = data
        // TODO: Follow https://github.com/apollographql/apollo-feature-requests/issues/4 and clear only relevant caches when possible
        store.$graphql.cache.reset()

        // TODO: Somehow let user know of what happened
        store.commit(
          'sadmin/applications/' + (event.type === 'application-deleted' ? 'removeApplication' : 'application'),
          event.application
        )
      })
      return authenticated
    }
  },
  state: {
    all: null
  },
  mutations: {
    applications: (state, applications) => { state.all = applications },
    application (state, payload) {
      if (!state.all) {
        state.all = []
      }
      const present = state.all.find(app => StringUtil.equals(app.id, payload.id))
      if (present) {
        Object.keys(payload).forEach(key => this.$set(present, key, payload[key]))
      } else {
        state.all.push(payload)
      }
    },
    removeApplication (state, application) {
      if (state.all) {
        state.all.splice(state.all.findIndex(a => StringUtil.equals(application.id, a.id)), 1)
      }
    }
  },
  actions: {
    load ({ state, commit }) {
      return this.$graphql.query({ query: ApplicationsQuery })
        .then(response => commit('applications', response.data.applications))
    },

    logs ({ state, commit }, object) {
      return this.$graphql.query({ query: ApplicationLogsQuery, variables: { id: object.id, serviceName: object.service } })
        .then(res => res.data)
    },
    details ({ dispatch, state }, id) {
      return this.$graphql.query({ query: ApplicationDetailsQuery, variables: { id } })
        .then(res => {
          const application = Object.assign({}, res.data.application, res.data.application.details)
          delete application.details
          return application
        })
    },
    trigger ({ commit }, { application, action }) {
      return this.$graphql.mutate({
        mutation: ApplicationTriggerMutation,
        variables: { applicationId: application.id, action }
      })
    },
    getTriggerHistory (store, applicationId) {
      return this.$graphql.query({ query: ApplicationTriggersQuery, variables: { id: applicationId } })
        .then(res => res.data.application.triggers)
    },
    save ({ commit }, { id, application: data }) {
      return this.$graphql.mutate({
        mutation: ApplicationSaveMutation,
        variables: { id, data }
      }).then(res => { commit('application', res.data.application); return res.data.application })
    },
    delete ({ dispatch }, { id }) {
      return this.$graphql.mutate({ mutation: ApplicationDeleteMutation, variables: { id } })
    }
  }
}
