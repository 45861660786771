<template>
  <footer class="app-footer">
    <span><a href="http://coreui.io">CoreUI</a> &copy; 2017 creativeLabs.</span>
    <span class="ml-auto">Powered by <a href="http://coreui.io">CoreUI</a></span>
  </footer>
</template>
<script>
  export default {
    name: 'Footer'
  }
</script>
