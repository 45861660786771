import User from '../models/User'
import AssetUtil from '@/util/AssetUtil'
import { baseUri } from '../../../graphql'
import UpdateSubscriptionTokenMutation from './graphql/UpdateSubscriptionTokenMutation.gql'

let keycloak
let keycloakInit
let keycloakTokenUpdate

export default {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    setUser (state, data) {
      state.user = new User({
        token: keycloak.token,
        ...keycloak.tokenParsed
      }, keycloak)
    }
  },
  actions: {
    init ({ rootState, commit, dispatch }) {
      if (keycloakInit) {
        return Promise.resolve(keycloak ? keycloak.authenticated : keycloakInit)
      }
      const env = rootState.config.modules.core.env
      keycloakInit = AssetUtil.loadJavaScript(this.$remote.core.auth.url('js/keycloak.js')).then(() => {
        const Keycloak = window.Keycloak
        keycloak = new Keycloak({
          realm: env.REALM,
          clientId: env.CLIENT_ID
        })
        keycloak.onTokenExpired = () => {
          dispatch('updateToken')
        }
        return promised(keycloak.init, { onLoad: 'check-sso' }).then(authenticated => {
          if (authenticated) {
            this.$remote.setTokenProvider(env.AUTH_BIND_URL || baseUri, () => {
              return dispatch('updateToken').then(() => 'Bearer ' + keycloak.token)
            })
            commit('setUser')
          }
          return authenticated
        })
      })
      return keycloakInit
    },
    login () {
      return promised(keycloak.login)
    },
    logout () {
      return promised(keycloak.logout)
    },
    updateToken ({ commit }) {
      if (!keycloakTokenUpdate && keycloak.isTokenExpired(5)) {
        console.log('Token expired')
        keycloakTokenUpdate = promised(keycloak.updateToken, -1).then(
          () => {
            keycloakTokenUpdate = undefined
            commit('setUser')
            this.$graphql.mutate({ mutation: UpdateSubscriptionTokenMutation })
          },
          (e) => {
            keycloakTokenUpdate = undefined
            keycloak.login()
            return Promise.reject(e)
          }
        )
      }
      return keycloakTokenUpdate
    }
  }
}

function promised (fn, ...args) {
  return new Promise((resolve, reject) => fn(...args).success(resolve).error(reject))
}
