<template>
  <div
    class="app-content-fixed d-flex align-items-center justify-content-center"
    style="max-height: 500px"
  >
    <div class="text-center">
      <i class="far fa-meh fa-10x text-danger" />
      <h3 class="mt-4">
        {{ $t('core.errors.' + (error || 'general')) }}
      </h3>
      <router-link
        v-if="error !== 'startup'"
        to="/"
      >
        {{ $t('core.errors.goHome') }}
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['error']
  }
</script>

<style lang="scss" rel="stylesheet/scss">

</style>
