import UriUtil from './UriUtil'
import axios from 'axios'
import extend from 'extend'

export default class ApiClient extends axios.Axios {
  _resolvingCache = {}
  _docs
  baseUrl
  specsUrl

  constructor (baseUrl, specsUrl) {
    super()
    this.baseUrl = baseUrl.replace(/\/+$/g, '/')
    this.specsUrl = specsUrl ? UriUtil.resolve(this.baseUrl, specsUrl) : null

    ;['request', 'response'].forEach(type => {
      this.interceptors[type].handlers.push(...axios.interceptors[type].handlers)
    })

    this.interceptors.request.use(
      (config) => Promise.resolve(this.url(config.url, config.method)).then((url) => { config.url = url; return config }),
      (error) => Promise.reject(error)
    )
  }

  url (path, method = 'GET') {
    console.log('Resolving ', path, this.specsUrl)
    if (path && path.match(/^https?:\/\//)) {
      return Promise.resolve(path)
    }
    if (!this.specsUrl) {
      return UriUtil.resolve(this.baseUrl, path)
    }
    return this.docs(true).then(
      (docs) => {
        if (docs.swagger) {
          const base = UriUtil.resolve(location.protocol + '//' + docs.host, docs.basePath)
          return UriUtil.resolve(base, path)
        } else {
          const cleanedPath = path.replace(/\?.+$/, '').replace(/^\/+/, '').replace(/\/+$/, '')
          const operation = (method || 'GET').toLowerCase()
          const cacheKey = operation + ':' + cleanedPath
          if (Object.prototype.hasOwnProperty.call(this._resolvingCache, cacheKey)) {
            return this._resolvingCache[cacheKey]
          }
          const baseUrl = resolveOpenApiBaseUrl(docs, cleanedPath, method)
          this._resolvingCache[cacheKey] = UriUtil.resolve(baseUrl || this.specsUrl, path)
          return this._resolvingCache[cacheKey]
        }
      }
    )
  }

  /**
   * Get the API docs for this remote
   *
   * api.docs().then(docs => console.log(docs))
   *
   * Dereference:
   * api.docs(true).then(spec => console.log(spec.discriminate('base_model', obj), spec))
   * // is the same as:
   * api.docs().then(
   *  raw => raw.dereference().then(
   *    spec => console.log(spec.discriminate('base_model', obj), spec)
   *  )
   * )
   *
   * @returns {Promise}
   */
  docs (getDereferenced = false) {
    if (getDereferenced) {
      return this.docs().then(spec => spec.dereference())
    }
    if (this._docs) {
      return Promise.resolve(this._docs)
    }
    this._docs = getSchemaParser()
      .then((parser) => parser.parse(this.specsUrl, parserOptions))
      .then((apiDocs) => {
        this._docs = createDocs(apiDocs, this.specsUrl)
        return this._docs
      })

    return this._docs
  }
}

function resolveOpenApiBaseUrl (docs, cleanedPath, operation) {
  const pathParts = cleanedPath.split('/')
  const specPath = Object.keys(docs.paths).find(docPath => {
    const docPathParts = docPath.replace(/^\/+/, '').replace(/\/+$/, '').split('/')
    if (docPathParts.length !== pathParts.length) {
      return false
    }
    for (let i = 0; i < pathParts.length; i++) {
      if (docPathParts[i] !== pathParts[i] && docPathParts[i][0] !== '{') {
        return false
      }
    }
    return true
  })
  let servers = docs.servers
  if (specPath) {
    const pathItemObject = docs.paths[specPath]
    if (!pathItemObject[operation]) {
      console.warn('No operation for method ' + operation.toUpperCase() + ' on path ' + specPath)
    } else if (pathItemObject[operation].servers && pathItemObject[operation].servers.length) {
      servers = pathItemObject[operation].servers
    } else if (pathItemObject.servers && pathItemObject.servers.length) {
      servers = pathItemObject.servers
    }
  }
  if (!servers || !servers.length) {
    console.debug('No servers configured for module')
    return null
  }
  return servers[0].url
}

function getSchemaParser () {
  if (!getSchemaParser.parser) {
    getSchemaParser.parser = import('swagger-parser').then(parser => {
      getSchemaParser.parser = parser
      return parser
    })
  }
  return Promise.resolve(getSchemaParser.parser)
}

const parserOptions = {
  resolve: {
    http: {
      read (file) {
        return axios.get(file.url).then(res => {
          return res.data
        })
      }
    }
  }
}

function createDocs (apiDocs, specsUrl) {
  let dereferenced
  if (apiDocs.swagger) {
    fixSwagger2Urls(apiDocs, specsUrl)
  }
  return {
    ...apiDocs,
    dereference () {
      if (dereferenced) {
        return Promise.resolve(dereferenced)
      }
      dereferenced = getSchemaParser().then(
        parser => parser.dereference(apiDocs, parserOptions).then(schema => {
          // Fix https://github.com/BigstickCarpet/swagger-express-middleware/issues/102
          const expandAllOf = (object) => {
            if (Object.prototype.hasOwnProperty.call(object, 'allOf')) {
              extend(true, object, ...object.allOf)
              delete object.allOf
            }
            Object.keys(object).forEach((key) => {
              if (typeof object[key] === 'object') {
                expandAllOf(object[key])
              }
            })
          }
          expandAllOf(schema.paths)
          expandAllOf(schema.definitions || {})
          dereferenced = createDocs(apiDocs, specsUrl)

          if (!apiDocs.swagger) {
            fixOAS3Urls(dereferenced, specsUrl)
          }

          dereferenced.dereference = () => Promise.resolve(dereferenced)
          return dereferenced
        })
      )
      return dereferenced
    },
    discriminate (undiscriminatedDefinitionName, model) {
      if (!this.definitions) {
        console.error('No definitions in spec')
        return null
      }
      let definition = undiscriminatedDefinitionName
      if (!Object.prototype.hasOwnProperty.call(this.definitions, definition)) {
        console.error(`Definition ${definition} not present in definitions`)
        return null
      }
      let schema = this.definitions[definition]
      while (schema.discriminator) {
        if (schema.allOf) {
          console.error('Dereference spec before discriminating')
          return null
        }
        const discriminatorValue = (model || {})[schema.discriminator]
        if (!discriminatorValue || discriminatorValue === definition || !Object.prototype.hasOwnProperty.call(this.definitions, discriminatorValue)) {
          break
        }
        definition = discriminatorValue
        schema = this.definitions[definition]
      }
      return schema
    }
  }
}

function fixSwagger2Urls (specs, specsUrl) {
  if (!specs.host || !specs.basePath || !specs.schemes) {
    const parsedSpecsUrl = UriUtil.parse(specsUrl)
    if (parsedSpecsUrl.host) {
      specs.host = parsedSpecsUrl.host + (parsedSpecsUrl.port ? ':' + parsedSpecsUrl.port : '')
    } else {
      specs.host = location.host + (location.port ? ':' + location.port : '')
    }
    if (!specs.schemes) {
      specs.schemes = [parsedSpecsUrl.scheme]
    } else if (specs.schemes.indexOf(parsedSpecsUrl.scheme) < 0) {
      specs.schemes.push(parsedSpecsUrl.scheme)
    }
    if (!specs.basePath || specs.basePath === './') {
      specs.basePath = '../'
    }
    specs.basePath = UriUtil.resolveComponents(parsedSpecsUrl, UriUtil.parse(specs.basePath)).path
  }
}

function fixOAS3Urls (specs, specsUrl) {
  const baseUrl = UriUtil.resolve(specsUrl, '../')
  const normalizeServers = function (entry) {
    if (!entry.servers) {
      return
    }
    entry.servers = entry.servers.filter(server => {
      if (server.variables) {
        console.error('No server variables supported (yet)')
        return false
      }
      server.url = UriUtil.resolve(baseUrl, server.url)
      return true
    })
  }
  if (!specs.servers) {
    specs.servers = [{ url: baseUrl }]
  } else {
    normalizeServers(specs)
  }
  Object.keys(specs.paths).forEach(path => {
    const pathItemsObject = specs.paths[path]
    normalizeServers(pathItemsObject)
    Object.values(pathItemsObject).forEach(pathItemObject => normalizeServers(pathItemObject))
  })
}
