<template>
  <div class="custom-progress">
    <transition name="custom-progress">
      <b-progress
        v-if="progress.total"
        :class="progress.current ? '' : 'custom-progress-indeterminate'"
        :value="progress.current"
        :max="progress.total"
        height="2px"
      />
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      current: Number,
      total: Number
    },
    data () {
      return {
        peakCurrent: 0,
        peakTotal: 0
      }
    },
    computed: {
      progress () {
        const progress = {
          current: Math.max(this.peakCurrent, this.current),
          total: Math.max(this.peakTotal, this.total)
        }

        if (this.current >= this.total) {
          progress.current = progress.total
          this.flatPeaks()
        }

        return progress
      }
    },
    methods: {
      flatPeaks () {
        window.setTimeout(() => {
          if (this.current >= this.total) {
            this.peakCurrent = 0
            this.peakTotal = 0
          }
        })
      }
    },
    watch: {
      progress: {
        immediate: true,
        handler (progress) {
          this.peakCurrent = Math.max(this.peakCurrent, progress.current)
          this.peakTotal = Math.max(this.peakTotal, progress.total)
        }
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss">
  .custom-progress {
    height: 2px;
    .progress {
      position: relative;
      &:before, &:after {
        content: '';
        position: absolute;
        background-color: rgba(#000, 0.125);
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
      }
      &.custom-progress-indeterminate {
        &:before {
          opacity: 1;
          animation: custom-progress-indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }
        &:after {
          opacity: 1;
          animation: custom-progress-indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation-delay: 1.15s;
        }
      }
    }
  }

  @keyframes custom-progress-indeterminate {
    0% { left: -35%; right: 100%; }
    60% { left: 100%; right: -90%; }
    100% { left: 100%; right: -90%; }
  }
  @keyframes custom-progress-indeterminate-short {
    0% { left: -200%; right: 100%; }
    60% { left: 107%; right: -8%; }
    100% { left: 107%; right: -8%; }
  }

  .custom-progress-leave-active {
    transition: opacity 0.5s;
  }
  .custom-progress-leave-to {
    opacity: 0;
  }
</style>
