export default {
  install (Vue) {
    improveBtn(Vue)
    // improvePopover(Vue)
    improveModal(Vue)
  }
}

/**
 * Several additions to bBtn
 *
 * @param Vue
 */
function improveBtn (Vue) {
  const bBtn = Vue.component('BButton')
  const orig = bBtn.options.render
  bBtn.options.props.icon = { type: String }
  bBtn.options.props.raised = { type: Boolean }
  bBtn.options.props.variant.default = 'primary'
  bBtn.options.render = function (h, context) {
    const classes = []
    if (context.props.icon) {
      const hasChildren = context.children && context.children.some(c => c.tag || c.text)
      const iconChild = h('i', { class: context.props.icon + (context.props.size ? ' fa-' + context.props.size : '') })
      classes.push('btn-icon')
      context.children = hasChildren ? [iconChild, h('span', { class: 'btn-text' }, context.children)] : [iconChild]
    }
    if (context.props.raised) {
      classes.push('btn-raised')
    }
    if (classes.length) {
      context.data.class = (context.data.class ? context.data.class + ' ' : '') + classes.join(' ')
    }
    return orig(h, context)
  }
}

/**
 * Implement closeOnClickOutside on popover
 * can be removed/replaced as soon as https://github.com/FezVrasta/popper.js/issues/300 is released
 *
 * @param Vue
 */
// function improvePopover (Vue) {
//   const bPopover = Vue.component('BPopover')
//   bPopover.options['custom-class'] = 'dark'
//   const toolpopMixin = bPopover.options.mixins[0]
//   const orig = toolpopMixin.methods.setObservers
//   toolpopMixin.methods.setObservers = function (show) {
//     orig.call(this, show)
//     if (this._closeListener) {
//       document.body.removeEventListener('click', this._closeListener)
//       document.removeEventListener('keyup', this._closeListener)
//       delete this._closeListener
//     }
//     if (show) {
//       this.$root.$emit('bv::hide::tooltip')
//       this._closeListener = (e) => {
//         if (
//           (!Object.prototype.hasOwnProperty.call(e, 'keyCode') || e.keyCode === 27) &&
//           this._toolpop &&
//           this._toolpop.$popper &&
//           this._toolpop.$popper.popper &&
//           !(this._toolpop.$popper.popper.contains(e.target)) &&
//           (!this._toolpop.$popper.reference || !this._toolpop.$popper.reference.contains(e.target))
//         ) {
//           this._toolpop.hide()
//         }
//       }
//       document.body.addEventListener('click', this._closeListener)
//       document.addEventListener('keyup', this._closeListener)
//     }
//   }
// }

function improveModal (Vue) {
  const modal = Vue.component('BModal').options
  Vue.component('BModal', {
    extends: modal,
    beforeDestroy () {
      if (this.reparented) {
        try {
          this.$root.$el.removeChild(this.$el)
        } catch (e) {}
      }
    },
    methods: {
      doShow () {
        this.isHidden = false
        this.$nextTick(() => {
          // We do this in nextTick to ensure the modal is in DOM first before we show it
          this.isVisible = true
          this.isOpening = false
          this.$emit('change', true)
          if (!this.reparented) {
            this.reparented = true
            this.$root.$el.appendChild(this.$el)
          }
        })
      },
      // Disable scrollbar measuring stuff
      getScrollbarWidth () {},
      resetScrollbar () {},
      setScrollbar () {},
      checkScrollbar () {},
      resetAdjustments () {},
      adjustDialog () {}
    },
    render (h) {
      if (this.isHidden) {
        return h('div')
      }
      return modal.render.call(this, h)
    }
  })
}
