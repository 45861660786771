import bus from './modules/bus'
import graphs from './modules/graphs'

export default {
  namespaced: true,
  modules: {
    bus, graphs
  },
  persist: ['currentDatahubId'],
  state: {
    currentDatahubId: null,
    datahubs: null
  },
  mutations: {
    currentDatahubId (state, datahubId) {
      state.currentDatahubId = datahubId
    },
    datahubs (state, datahubs) {
      state.datahubs = datahubs
    }
  }
}
