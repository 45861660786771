<template>
  <!-- <div class="sidebar-footer"></div> -->
  <div></div>
</template>
<script>

  export default {
    name: 'SidebarFooter'
  }
</script>
