<template>
  <!-- <div class="sidebar-header"></div> -->
  <div></div>
</template>
<script>

  export default {
    name: 'SidebarHeader'
  }
</script>
