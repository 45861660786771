export default class User {
  constructor (data) {
    if (data.attributes && data.attributes !== null) {
      if (data.attributes.isCreatedByUi) {
        this.isCreatedByUi = data.attributes.isCreatedByUi
      } else {
        this.isCreatedByUi = false
      }
    }
    this.isSandboxUser = (data.isSandboxUser !== undefined) ? data.isSandboxUser : null
    this.id = data.id || data.sub
    this.username = data.username || data.preferred_username
    this.email = data.email
    const name = data.name || (data.username || data.email.replace(/@.+$/, '')).replace(/[.\-+_]/g, ' ')
    this.firstName = data.firstName || data.given_name || name.replace(/ (.+?)$/, '')
    this.lastName = data.lastName || data.family_name || name.replace(/(.+)( .*?)?$/, '$2')
    this.initials = (this.firstName[0] || '').toUpperCase() + (this.lastName[0] || '').toUpperCase()
    this.picture = data.picture

    const { roles: realmRoles } = data.realm_access || {}
    this.isAdmin = data.isAdmin || (realmRoles && realmRoles.indexOf('shopfactory_admin') > -1) || false
    this.isDev = (realmRoles && realmRoles.indexOf('blugento_dev') > -1) || false
    this.token = data.token || null
    this.accountUrl = data.accountUrl || ''
  }
}
