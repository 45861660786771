import axios from 'axios'
import { buildAxiosFetch } from 'axios-fetch'
import ApolloClient from 'apollo-client'
import { onError } from 'apollo-link-error'
import { gql, InMemoryCache, HttpLink, split, ApolloLink } from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import Remote from './remote'

import Vuex from 'vuex'

const isProd = process.env.NODE_ENV === 'production'

const baseUri = isProd ? location.origin : (process.env.FRONTDESK_URL || location.protocol + '//' + location.hostname + ':4000')
const endpoint = baseUri + '/graphql'
const subscriptionEndpoint = baseUri.replace(/^http/, 'ws') + '/subscriptions'

const networkLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  new WebSocketLink({
    uri: subscriptionEndpoint,
    options: {
      // The {{lazy}} is not only efficient but actually necessary because
      // otherwise the connection_init message with the {{connectionParams()}}
      // isn't reliably sent before subscription start messages
      lazy: true,
      reconnect: true,
      reconnectionAttempts: Infinity,
      connectionParams () {
        return Remote.getToken(baseUri, true).then(authorization => ({ authorization }))
      }
    }
  }),
  new HttpLink({
    uri: endpoint,
    fetch: buildAxiosFetch(axios)
  })
)

const graphql = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    onError(function (res) {
      if (res.graphQLErrors) {
        res.graphQLErrors.forEach(
          e => console.error(`[GraphQL error]: Message: ${e.message}, Location: ${e.locations}, Path: ${e.path}`)
        )
      }
      if (res.networkError) {
        console.error(`[Network error]: ${res.networkError}`)
      }
    }),
    networkLink
  ])
})

export { gql, graphql, baseUri, endpoint, subscriptionEndpoint, networkLink }
export default graphql

Vuex.Store.prototype.$graphql = graphql
