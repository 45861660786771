import EnvironmentsQuery from './graphql/EnvironmentsQuery.gql'
import EnvironmentSaveMutation from './graphql/EnvironmentsSaveMutation.gql'
import EnvironmentDeleteMutation from './graphql/EnvironmentsDeleteMutation.gql'
import EnvironmentDetailsQuery from './graphql/EnvironmentDetailsQuery.gql'
import AccountCostQuery from './graphql/AccountCostQuery.gql'

function normalize (environment) {
  const target = { ...environment, ...(environment.details || {}) }
  delete target.details
  delete target.__typename
  return target
}

export default {
  namespaced: true,
  state: {
    all: null
  },
  mutations: {
    environments (state, environments) {
      state.all = environments.map(normalize)
    },
    environment (state, environment) {
      environment = normalize(environment)
      if (!state.all) {
        state.all = []
      }
      const index = state.all.findIndex(e => e.id === environment.id)
      if (index > -1) {
        state.all.splice(index, 1, environment)
      } else {
        state.all.push(environment)
      }
    },
    deleteEnvironment (state, id) {
      if (state.all) {
        state.all.splice(state.all.findIndex(e => e.id === id), 1)
      }
    }
  },
  actions: {
    load ({ state, commit }, reload = false) {
      if (state.all && !reload) {
        return
      }
      return this.$graphql.query({ query: EnvironmentsQuery, fetchPolicy: 'network-only' })
        .then(response => commit('environments', response.data.environments))
    },
    details ({ state, commit }, id) {
      return this.$graphql.query({ query: EnvironmentDetailsQuery, variables: { id } })
        .then(res => {
          const environment = Object.assign({}, res.data.environment, res.data.environment.details)
          delete environment.details
          return environment
        })
    },
    save ({ commit }, { id, environment: data }) {
      return this.$graphql.mutate({ mutation: EnvironmentSaveMutation, variables: { id, data } })
        .then(res => { commit('environment', res.data.environment); return res.data.environment })
    },
    delete ({ commit }, { id }) {
      return this.$graphql.mutate({ mutation: EnvironmentDeleteMutation, variables: { id } })
        .then(res => commit('deleteEnvironment', id))
    },
    cost ({ commit }, variables) {
      if (variables.to) {
        return this.$graphql.query({ query: AccountCostQuery, variables: { envId: variables.envId, from: variables.from, to: variables.to } })
      } else {
        return this.$graphql.query({ query: AccountCostQuery, variables: { envId: variables.envId } })
      }
    }
  }
}
