<template>
  <select
    :id="id"
    v-model="selected"
    class="form-control"
    :class="warning"
    @change="updateValue"
  >
    <option
      value="null"
      disabled
    >
      {{ pleaseSelect }}
    </option>
    <option
      v-for="option in options"
      :key="'option-' + option.id"
      :value="option.id"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
  import { abstractField } from 'vue-form-generator'
  export default {
    name: 'FieldCustomSelect',
    mixins: [abstractField],
    data () {
      return {
        id: this.schema.label.toLowerCase(),
        selected: (this.schema.values.length > 1) ? null : this.schema.values[0].id,
        options: this.schema.values,
        selectType: '',
        pleaseSelect: this.$t('plsSelect', { type: this.selectType })
      }
    },
    computed: {
      warning () {
        return (this.schema.hint === '' || this.schema.hint === undefined) ? null : 'border-warning'
      }
    },
    mounted () {
      if (this.value === null || this.value === undefined) {
        this.value = this.selected
      } else {
        this.selected = this.value
      }
    },
    methods: {
      updateValue () {
        this.value = this.selected
      }
    }
  }
</script>
