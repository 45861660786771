<script>
  import { abstractField } from 'vue-form-generator'

  export default {
    mixins: [abstractField],
    render (createElement) {
      const field = this.schema
      return createElement(
        'div',
        { class: 'w-100 alert alert-' + (field.variant || 'warning') },
        typeof field.message === 'function' ? field.message(createElement, this) : field.message
      )
    }
  }
</script>
