export const routeBackMixin = {
  data () {
    return {
      prevRoute: null
    }
  },
  /**
   * Check previouse route point/path
   *
   * @return void
   */
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    /**
     * Find and push last predecessor
     * If page were reloaded it pushes to dashboard
     *
     * @return void
     */
    back () {
      if (this.prevRoute.name === null) {
        this.$router.push({ path: '/' })
      } else {
        this.$router.push({ path: this.prevRoute.path })
      }
    }
  }
}
