import FormSchemaQuery from './graphql/FormSchemaQuery.gql'
import SystemsQuery from './graphql/SystemsQuery.gql'
import applications from './applications'
import environments from './environments'

export default {
  namespaced: true,
  modules: { applications, environments },
  persist: ['aside', 'applications'],
  state: {
    aside: false,
    widgets: false,
    systems: [],
    systemsLoaded: false,
    accounts: false
  },
  wrapActions: {
    'core/initApp': (action, store) => async app => {
      const authenticated = await action(app)
      if (!authenticated) {
        return authenticated
      }
      await store.dispatch('sadmin/loadSystems')
      store.watch(() => store.state.core.authz.teams, () => store.dispatch('sadmin/loadSystems'))
      return authenticated
    }
  },
  mutations: {
    aside (state, aside) {
      state.aside = (aside === state.aside) ? null : aside
    },
    widgets (state, widgets) {
      state.widgets = (widgets === state.widgets) ? null : widgets
    },
    systems (state, systems) {
      state.systems = systems
      state.systemsLoaded = true
    }
  },
  actions: {
    getFormSchema (_, entity) {
      return this.$graphql.query({ query: FormSchemaQuery, variables: { entity } }).then(
        response => response.data.schema
      )
    },
    loadSystems ({ commit }) {
      return this.$graphql.query({ query: SystemsQuery, fetchPolicy: 'no-cache' })
        .then(response => commit('systems', response.data.systems))
    }
  }
}
