export default function (Vue) {
  Vue.mixin({
    methods: {
      $isAllowed (scope, teamName = null) {
        if (!this.$store.state.core.auth.user) {
          return false
        }
        if (this.$store.state.core.auth.user.isAdmin) {
          return true
        }
        let teams = this.$store.state.core.authz.teams
        if (!teams) {
          return false
        }
        if (teamName) {
          const teamNames = Array.isArray(teamName) ? teamName : [teamName]
          teams = teams.filter(team => teamNames.indexOf(team.name) > -1)
        }
        return teams.some(team => team.scopes.indexOf(scope) > -1)
      }
    }
  })
}
