export default class Progress {
  constructor () {
    this.reset()
  }

  reset () {
    this.current = 0
    this.total = 0
  }

  promise (promise) {
    this.total++
    if (this.current > this.total) {
      this.current = this.total
    }

    const done = () => {
      this.current += 1
      if (this.current >= this.total) {
        this.reset()
      }
    }
    return Promise.resolve(promise).then(
      (payload) => {
        done()
        return payload
      },
      (error) => {
        done()
        return Promise.reject(error)
      }
    )
  }

  promiseAll (promises) {
    return Promise.all(promises.map(promise => this.promise(promise)))
  }
}
