export default {
  name: 'duration',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    from: {
      type: [Number, String, Array, Object]
    },
    to: {
      type: [Number, String, Array, Object],
      required: true
    },
    noSuffix: {
      type: Boolean
    },
    titleFormat: {
      type: String,
      default: 'L LTS'
    }
  },
  data () {
    return { duration: null }
  },
  created () {
    this.$watch(
      () => this.from + '||' + this.to,
      () => this.updateDuration(),
      { immediate: true }
    )
  },
  render (createElement, context) {
    const duration = this.duration
    if (!duration) {
      return null
    }
    const props = { class: 'moment-duration', attrs: {} }
    if (this.titleFormat && this.titleFormat !== 'none') {
      props.attrs.title = (this.from ? this.$moment(this.from).format(this.titleFormat) + ' - ' : '') +
        this.$moment(this.to).format(this.titleFormat)
    }
    let content
    if (this.$scopedSlots.default) {
      content = this.$scopedSlots.default({
        duration: this.duration,
        isFuture: +duration > 0,
        get humanized () {
          return duration.humanize(false)
        }
      })
    } else {
      content = this.duration.humanize(!this.noSuffix)
    }
    return createElement(this.tag, props, content)
  },
  methods: {
    updateDuration () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        delete this.timeoutId
      }
      this.duration = this.$moment.duration(this.$moment(this.to).diff(this.$moment(this.from)))
      if (!this.from) {
        const duration = this.duration.clone().abs()
        let timeout
        if (duration.asMinutes() < 1) {
          timeout = 1000
        } else if (duration.asHours() < 1) {
          timeout = (60 - duration.seconds()) * 1000
        } else {
          timeout = (60 - duration.minutes()) * 60 * 1000 - (duration.seconds()) * 1000
        }

        const timeoutId = setTimeout(() => {
          if (this.timeoutId === timeoutId) {
            delete this.timeoutId
            this.updateDuration()
          }
        }, timeout)
        this.timeoutId = timeoutId
      }
    },
    beforeDestroy () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        delete this.timeoutId
      }
    }
  }
}
