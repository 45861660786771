import dashboard from './dashboard'
import auth from './auth'
import authz from './authz'
import settings from './settings'
import App from '../App'
import tracking from '../vue-plugins/tracking'
import pushNote from '../vue-plugins/pushNote'

export default {
  namespaced: true,
  modules: { dashboard, auth, authz, settings },
  actions: {
    initApp ({ dispatch, rootState }, app) {
      app.options.extends = App

      app.Vue.use(tracking, { app, gaId: rootState.config.modules.core.env.GA_ID })
      app.Vue.use(pushNote, { app })
      return dispatch('auth/init')
    }
  }
}
