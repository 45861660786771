<template>
  <a
    v-if="isExternalLink"
    :href="url.path"
    :class="classList"
    :target="isExternalLink ? '_blank' : null"
  >
    <i :class="icon" /> {{ $t(name) }}
    <b-badge
      v-if="badge && badge.text"
      :variant="badge.variant"
    >{{ badge.text }}</b-badge>
  </a>
  <router-link
    v-else
    :to="to"
    :class="classList"
  >
    <i :class="icon" /> {{ $t(name) }}
    <b-badge
      v-if="badge && badge.text"
      :variant="badge.variant"
    >
      {{ badge.text }}
    </b-badge>
  </router-link>
</template>

<script>
  export default {
    name: 'SidebarNavLink',
    props: {
      name: {
        type: String,
        default: ''
      },
      url: {
        type: [String, Object, Function],
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      badge: {
        type: Object,
        default: () => {}
      },
      variant: {
        type: String,
        default: ''
      },
      classes: {
        type: String,
        default: ''
      }
    },
    computed: {
      classList () {
        return [
          'nav-link',
          this.linkVariant,
          ...this.itemClasses
        ]
      },
      linkVariant () {
        return this.variant ? `nav-link-${this.variant}` : ''
      },
      itemClasses () {
        return this.classes ? this.classes.split(' ') : []
      },
      to () {
        const url = this.url
        return typeof url === 'function' ? url.call(this) : url
      },
      isExternalLink () {
        return typeof this.url.path === 'string' && this.url.path.substring(0, 4) === 'http'
      }
    }
  }
</script>
