import fieldCardSelect from './fieldCardSelect.vue'
import fieldAlert from './fieldAlert.vue'
import fieldCustomSelect from './fieldCustomSelect.vue'
import fieldEnvironmentSelect from './environments/fieldEnvironmentSelect.vue'

export default function (Vue) {
  Vue.component('field-card-select', fieldCardSelect)
  Vue.component('field-alert', fieldAlert)
  Vue.component('field-customSelect', fieldCustomSelect)
  Vue.component('field-environmentSelect', fieldEnvironmentSelect)
}
