<template>
<div>
  <span id="popoverTrigger">
    <avatar
      ref="avatar"
      class="cursor-pointer"
      :size="size"
      :user="user"
    />
  </span>
  <b-popover target="popoverTrigger" triggers="click focus" id="avatarPopover" placement="bottomleft" ref='popover'>
      <div
        class="d-inline-flex py-1"
      >
        <avatar
          class="mr-3"
          size="lg"
          :user="user"
        />
        <div class="pt-2">
          <h6 class="my-1">{{ user.firstName }} {{ user.lastName }}</h6>
          <div class="text-secondary">{{ user.email }}</div>
          <slot />
        </div>
      </div>
  </b-popover>
</div>
</template>

<script>
  import User from '../models/User'

  export default {
    props: {
      user: { type: User, required: true },
      size: String
    }
  }
</script>
