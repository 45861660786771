export default class RemoteUtil {
  static postOrPatch (path, id) {
    let method = 'post'
    if (id) {
      method = 'put'
      path += '/' + id
    }
    return { method, path }
  }
}
