export default class Control {
  constructor (vfgCfg, options) {
    this.steps = []
    this.step = undefined
    this.vfgCfg = vfgCfg
    this.hasPrevious = false
    this.hasNext = false
    this.validated = false

    // TODO: Make on the fly validation work
    this.canContinue = false
  }

  setSchema (schema) {
    const steps = getSteps(schema.fields, null)

    schema.groups.forEach(group => {
      getSteps(group.fields, group.step || null).forEach(step => {
        getStep(steps, step.key).groups.push({
          label: group.label,
          fields: step.fields
        })
      })
    })

    sortSteps(steps)

    this.steps = steps
    this.toStep(this.getCurrentStepIndex())
  }

  getCurrentStepIndex () {
    let stepIndex = this.steps.findIndex(s => s.key === this.step)
    if (stepIndex < 0) {
      stepIndex = this.steps.length > 0 ? 0 : -1
    }
    return stepIndex
  }

  toStep (stepIndex) {
    this.validated = false
    this.hasPrevious = stepIndex > 0
    this.hasNext = stepIndex < this.steps.length - 1
    const step = stepIndex < 0 ? {} : this.steps[stepIndex]
    this.vfgCfg.schema = step
    this.step = step.key
  }

  toNextStep ($vfg = null) {
    if (this.hasNext) {
      ($vfg ? $vfg.validate(true) : Promise.resolve([])).then(errors => {
        if (errors.length) {
          this.validated = true
        } else {
          this.toStep(this.getCurrentStepIndex() + 1)
        }
      })
    }
  }

  toPreviousStep () {
    if (this.hasPrevious) {
      this.toStep(this.getCurrentStepIndex() - 1)
    }
  }

  validate ($vfg) {
    return $vfg.validate(true).then((errors) => {
      this.validated = true
      return errors
    })
  }
}

function getStep (steps, key) {
  let target = steps.find(s => s.key === key)
  if (!target) {
    target = { key, fields: [], groups: [] }
    steps.push(target)
  }
  return target
}

function getSteps (fields, defaultStep) {
  const steps = []
  fields.forEach(field => {
    if (Object.prototype.hasOwnProperty.call(field, 'visible') && !field.visible) {
      return
    }
    getStep(steps, field.step || defaultStep).fields.push(field)
  })
  return steps
}

function sortSteps (steps) {
  steps.sort((a, b) => {
    switch (true) {
    case a === null: return 1
    case b === null: return -1
    case a < b: return -1
    case a > b: return 1
    }
    return 0
  })
}
