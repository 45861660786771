export default class Tracker {
  accessed = {}

  reset () {
    this.accessed = {}
  }

  trackAccess (field) {
    this.accessed[field] = true
  }

  wasAccessed (field) {
    return this.accessed[field] || false
  }
}
