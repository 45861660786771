const singularToPluralMap = {
  cms: 'cms'
}
const pluralToSingularMap = {
  cms: 'cms'
}

export default class StringUtil {
  static isString (str) {
    return typeof str === 'string'
  }

  static singularize (str) {
    if (StringUtil.isString(str)) {
      if (Object.prototype.hasOwnProperty.call(pluralToSingularMap, str)) {
        return pluralToSingularMap[str]
      }
      return str.replace(/ies$/, 'y').replace(/s$/, '')
    }
  }

  static pluralize (str) {
    if (StringUtil.isString(str)) {
      if (Object.prototype.hasOwnProperty.call(singularToPluralMap, str)) {
        return singularToPluralMap[str]
      }
      return str.replace(/y$/, 'ie') + 's'
    }
  }

  static equals (...str) {
    return str.every((v, i) => (i > 0 ? ('' + str[i - 1] === '' + v) : true))
  }
}
