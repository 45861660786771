<template>
  <header class="app-header navbar p-0">
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click.prevent="mobileSidebarToggle"
    />
    <button
      class="sidebar-modal"
      @click.prevent="mobileSidebarToggle"
    />
    <b-link
      v-track:click="{action:'logo', category: 'header'}"
      class="navbar-brand"
      to="/"
      :style="[ !logo || { backgroundImage: 'url(' + logo + ')' }]"
      :title="title"
    />
    <div class="navbar-text mr-auto ml-3">
      <span
        v-if="rootline && rootline.length"
        class="text-capitalize h4 font-weight-light"
      >{{ showName(rootline[0]) }}</span>
    </div>
    <slot />
  </header>
</template>
<script>
  export default {
    props: {
      rootline: Array
    },
    data () {
      return {
        mobileSidebarShown: false
      }
    },
    computed: {
      logo () {
        return process.env.LOGO || false
      },
      title () {
        return process.env.TITLE || 'Blugento'
      }
    },
    watch: {
      $route () {
        if (this.mobileSidebarShown) {
          this.mobileSidebarToggle()
        }
      }
    },
    created: function () {
      document.title = process.env.TITLE || 'Blugento'
    },
    methods: {
      sidebarToggle (e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-hidden')
      },
      sidebarMinimize (e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-minimized')
      },
      mobileSidebarToggle () {
        document.body.classList.toggle('sidebar-mobile-show')
        this.mobileSidebarShown = !this.mobileSidebarShown
      },
      asideToggle (e) {
        e.preventDefault()
        document.body.classList.toggle('aside-menu-hidden')
      },
      showName (item) {
        if (item.meta.label) {
          item = item.meta.label
        }
        return this.$t(item)
      }
    }
  }
</script>
