import store from './store'
import Dashboard from './views/Dashboard'
import Error from './views/Error'

export default {
  routes: {
    dashboard: {
      icon: 'icon-home',
      // sidebar: true,
      component: Dashboard
    },
    error: {
      path: '/error/:error?',
      props: { default: true },
      component: Error,
      meta: { label: 'Sorry' }
    },
    dev: {
      component: () => import(/* webpackChunkName: "dev" */ './views/dev/Index.vue')
    },
    feedback: {
      icon: 'fas fa-comments',
      sidebar: true,
      last: true,
      path: '/feedback',
      components: {
        default: () => import(/* webpackChunkName: "feedback" */ './views/evaluation/Feedback.vue')
      }
    },
    settings: {
      icon: 'icon-settings',
      sidebar: true,
      last: true,
      condition () {
        return this.$isAllowed('manage_team') || this.$isAllowed('manage_team_members') || this.$isAllowed('administer')
      },
      components: {
        toolbar: () => import(/* webpackChunkName: "settings" */ './views/settings/Toolbar.vue')
      },
      children: {
        iam: {
          children: {
            index: {
              component: () => import(/* webpackChunkName: "settings" */ './views/settings/Iam.vue')
            },
            team: {
              path: 'teams/:team',
              component: () => import(/* webpackChunkName: "settings" */ './views/settings/iam/Team.vue')
            }
          }
        }
      }
    }
  },
  store,
  env: {
    AUTH_API_URL: (process.env.FRONTDESK_URL || '') + '/auth',
    AUTH_BIND_URL: null,
    REALM: 'blugento',
    CLIENT_ID: 'ui',
    GA_ID: (process.env.GA_ID || false)
  },
  remote: {
    path: '',
    apiDocs: false,
    children: {
      auth: {
        apiDocs: false
      }
    }
  }
}
