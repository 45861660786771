import VueI18n from 'vue-i18n'

const available = ['en']

export default class Lang extends VueI18n {
  static DEFAULT_LANGUAGE = (navigator.language || navigator.userLanguage).replace(/^([a-z][a-z]).+$/, '$1')

  constructor (modules) {
    super({
      messages: {},
      locale: 'none',
      missing: (locale, key) => {
        if (locale !== 'none' && process.ENV !== 'production') {
          console.warn(`Cannot translate  '${key}' for locale '${locale}'`)
        }
        const humanized = key.replace(/^.*?([^.]+( .+)|[^.]*?)(\._)?$/, '$1')
        return humanized[0].toUpperCase() + humanized.substr(1)
      }
    })
    this.modules = modules
  }

  setLanguage (language) {
    if (!language) {
      language = Lang.DEFAULT_LANGUAGE
    }
    const actual = available.indexOf(language) < 0 ? available[0] : language
    return import('./' + actual).then((localesModule) => {
      const locales = localesModule.default
      const promises = []
      this.modules.forEach((mod) => {
        promises.push(
          import('../modules/' + mod + '/lang/' + actual).then(
            (moduleLocales) => {
              locales[mod] = moduleLocales.default
            },
            (error) => {
              console.error(error)
            }
          )
        )
      })
      return Promise.all(promises).then(() => {
        this.setLocaleMessage(actual, locales)
        this.locale = actual
        return actual
      })
    })
  }
}
