import { resolveComponents, parse, serialize } from 'uri-js'

function doResolveComponents (...urls) {
  let last = urls.shift()
  for (let i = 0; i < urls.length; i++) {
    let next = urls[i]
    if (!next.scheme && next.path && next.path[0] !== '/') {
      next = Object.assign({}, next)
      const nextPathParts = []
      ;(last.path ? last.path.replace(/\/+$/, '').split('/') : [])
        .concat(next.path.replace(/\/+$/, '').split('/'))
        .forEach(part => {
          if (part === '..') {
            nextPathParts.pop()
          } else if (part !== '.') {
            nextPathParts.push(part)
          }
        })
      next.path = nextPathParts.join('/') || '/'
    }
    last = resolveComponents(last, next)
  }
  last.path = last.path.replace(/\/+$/, '').replace(/\/+/g, '/')
  return last
}

export default class UriUtil {
  static resolve (...urls) {
    return serialize(doResolveComponents(...urls.filter(u => !!u).map(u => UriUtil.parse(u))))
  }

  static resolveComponents (...urls) {
    return doResolveComponents(...urls.filter(u => !!u).map(u => Object.assign({}, u)))
  }

  static parse (uri) {
    return parse(uri)
  }
}
