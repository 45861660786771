export default {
  namespaced: true,
  persist: ['compose', 'compositionTab', 'compositionSelection'],
  state: {
    compose: true,
    compositionTab: 0,
    compositionSelection: {}
  },
  mutations: {
    toggleCompose (state) {
      state.compose = !state.compose
    },
    compositionTab (state, index) {
      state.compositionTab = index
    },
    compositionSelection (state, selection) {
      state.compositionSelection = selection
    }
  }
}
