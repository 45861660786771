export default class Context {
  constructor (schemas, models, context, progress, tracker) {
    Object.keys(models).concat('this').forEach(key => {
      if (Object.prototype.hasOwnProperty.call(context, key)) {
        throw new Error(`context may not have a "${key}" property`)
      }
    })

    this.progress = progress
    this.tracker = tracker
    this.models = models

    this.context = this._createLazyGetters(context)
    this._addSchemaFields(schemas)
    this._addModelFields()
  }

  scope (scope) {
    const context = {}
    Object.defineProperty(context, 'this', { enumerable: true, get: () => { return this.context[scope] } })
    Object.keys(this.context).forEach(key => {
      Object.defineProperty(context, key, { enumerable: true, get: () => this.context[key] })
    })
    return context
  }

  _addSchemaFields (schemas) {
    const context = this.context
    const fields = []
    Object.keys(schemas).forEach(scope => {
      fields.push([scope])
      new Array(schemas[scope]).concat(schemas[scope].groups || []).forEach(
        group => Object.keys(group.fields || {}).forEach(key => fields.push((scope + '.' + key).split('.')))
      )
      fields.sort((a, b) => b.length - a.length)
      fields.forEach(path => {
        let parent
        path.forEach((part, i) => {
          if (i === 0) {
            if (!context[part]) {
              context[part] = {}
            }
            parent = context[part]
          } else if (i < path.length - 1) {
            if (!Object.prototype.hasOwnProperty.call(parent, part)) {
              parent[part] = {}
            }
            parent = parent[part]
          } else if (!Object.prototype.hasOwnProperty.call(parent, part)) {
            const strPath = path.join('.')
            Object.defineProperty(parent, part, {
              enumerable: true,
              get: () => {
                this.tracker.trackAccess(strPath)
                let obj = this.models
                for (let i = 0; i < path.length - 1; i++) {
                  obj = obj[path[i]]
                  if (typeof obj !== 'object') {
                    return undefined
                  }
                }
                return obj[part]
              }
            })
          }
        })
      })
    })
  }

  _addModelFields () {
    const context = this.context
    Object.keys(this.models).forEach(key => {
      const target = context[key]
      Object.keys(this.models[key]).forEach(name => {
        if (!Object.prototype.hasOwnProperty.call(target, name)) {
          Object.defineProperty(target, name, {
            enumerable: true,
            get: () => this.models[key][name]
          })
        }
      })
    })
  }

  _createLazyGetters (object) {
    if (typeof object !== 'object') {
      return object
    }
    if (Array.isArray(object)) {
      return object.map(this._createLazyGetters.bind(this))
    }
    const answer = {}
    Object.keys(object || {}).forEach(key => {
      const value = object[key]
      if (typeof value === 'function') {
        let result
        let called = false
        Object.defineProperty(answer, key, {
          enumerable: true,
          get: () => {
            if (called) {
              return result
            }
            called = true
            result = this.progress.promise(value()).then(r => {
              result = this._createLazyGetters(r)
              return result
            })
            return result
          }
        })
      } else {
        answer[key] = this._createLazyGetters(value)
      }
    })
    return answer
  }
}
