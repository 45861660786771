<template>
  <div class="app" :class="darkMode ? 'dark' : 'light'">
    <template v-if="user">
      <AppHeader :rootline="rootline">
        <profile-avatar :user="user" class="mr-3" v-track:click="{action: 'avatar menu', category: 'user'}">
          <div><a :href="manageAccount" target="_blank">{{$t('core.account.manage')}}</a></div>
          <div class="custom-control custom-switch mt-1">
            <input type="checkbox" @change="toggleDarkMode" :checked="darkMode" class="custom-control-input" id="customSwitch"
            >
            <label for="customSwitch" class="custom-control-label h5">Darkmode</label>
          </div>
          <b-btn raised class="mt-2" @click="$store.dispatch('core/auth/logout')" v-track:click="{action: 'logout', category: 'header'}">Logout</b-btn>
        </profile-avatar>
      </AppHeader>
      <Sidebar :navItems="nav"/>
      <div class="app-body">
        <router-view name="toolbar"></router-view>
        <main class="main" :class="addAdminClass ? 'sadmin' : ''">
          <div class="container-fluid h-100">
            <router-view></router-view>
          </div>
        </main>
        <Notifications></Notifications>
      </div>
    </template>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'
  import * as components from '../../components/'
  import ProfileAvatar from './components/ProfileAvatar'
  import Avatar from './components/Avatar'

  Vue.component('app-aside', components.Aside)
  Vue.component('vfg-form', components.VfgForm)
  Vue.component('profile-avatar', ProfileAvatar)
  Vue.component('avatar', Avatar)

  export default {
    name: 'app',
    mixins: ['tracking'],
    data () {
      return {
        addAdminClass: false
      }
    },
    created () {
      this.$store.dispatch('core/auth/init').then(authenticated => {
        if (!authenticated) {
          this.$store.dispatch('core/auth/login')
        }
      })

      if (this.$store.state.core.settings.darkMode) {
        document.body.classList.add('dark')
      }
    },
    components: {
      AppHeader: components.Header,
      Sidebar: components.Sidebar,
      Notifications: components.Notifications
    },
    watch: {
      lang: {
        immediate: true,
        handler (lang) {
          this.$i18n.setLanguage(lang)
        }
      },
      $route () {
        const currentPath = this.$route.path
        const isAdmin = currentPath.indexOf('sadmin')
        const isAdminDetail = currentPath.indexOf('applications')
        isAdmin === 1 || isAdminDetail === 1 ? this.addAdminClass = true : this.addAdminClass = false
      }
    },
    computed: {
      rootline () {
        return this.$route.matched
      },
      ...mapState(['lang']),
      ...mapState('core/auth', ['user']),
      manageAccount () {
        return this.$store.state.config.env.AUTH_API_URL + '/realms/' + this.$store.state.config.env.REALM + '/account'
      },
      darkMode () {
        return (this.$store.state.core.settings.darkMode)
      }
    },
    methods: {
      toggleDarkMode (e) {
        e.preventDefault()
        this.$store.commit('core/settings/set', { setting: 'darkMode', value: !this.darkMode })
        this.track({ action: this.darkMode, label: 'toggle darkMode', category: 'user' })
        if (this.darkMode) {
          document.body.classList.add('dark')
        } else {
          document.body.classList.remove('dark')
        }
      }
    },
    mounted () {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && this.darkMode === undefined) {
        this.toggleDarkMode()
      }
    }
  }
</script>
<style>
  /* .app {
    background-color: var(--primary-background-color);
    color: var(--primary-font-color);
  } */
</style>
