export default class Config {
  constructor (progress) {
    this.progress = progress
    this.models = null
    this.schemas = null
    this.context = null
  }

  load (rawConfig) {
    const finalConfig = { context: {}, schemas: {}, models: {} }
    const orders = { context: {}, schemas: {}, models: {} }
    return this.progress.promiseAll(
      (Array.isArray(rawConfig) ? rawConfig : new Array(rawConfig)).map(
        (rc, order) => Promise.resolve(rc).then(config => Object.assign({ order }, config))
      )
    ).then(
      configs => Promise.all(configs.map(config => {
        if (!config.schemas || !config.models) {
          throw new Error('models and schemas are required')
        }
        const promises = []
        Object.keys(finalConfig).forEach(key => {
          promises.push(this.progress.promise(config[key]).then(value => {
            if (!value) {
              return
            }
            if (typeof value !== 'object') {
              throw new Error(key + ' must be object')
            }
            const targetPromises = []
            finalConfig[key] = {}
            Object.keys(value).forEach((targetKey, order) => {
              targetPromises.push(this.progress.promise(value[targetKey]).then(targetValue => {
                if (key !== 'context' && typeof targetValue !== 'object') {
                  throw new Error(key + '.' + targetKey + ' must be object')
                }
                if (Object.prototype.hasOwnProperty.call(finalConfig[key], targetKey)) {
                  throw new Error(
                    `Configs may not override each others models, schemas or contexts 
                    (conflicting properties for ${key}.${targetKey}`
                  )
                }
                finalConfig[key][targetKey] = targetValue
                orders[key][targetKey] = [config.order, order]
              }))
            })
            return Promise.all(targetPromises)
          }))
        })
        return Promise.all(promises)
      }))
    ).then(() => {
      Object.keys(finalConfig.models).concat(Object.keys(finalConfig.schemas)).forEach(key => {
        if (typeof finalConfig.models[key] !== 'object' || typeof finalConfig.schemas[key] !== 'object') {
          throw new Error('models must have related schemas and vice versa and both must be objects')
        }
      })
      Object.keys(finalConfig).forEach(key => {
        const sorted = {}
        const sortedKeys = Object.keys(finalConfig[key])
        sortedKeys.sort((a, b) => orders[key][a][0] - orders[key][b][0] || orders[key][a][1] - orders[key][b][1])
        sortedKeys.forEach(subKey => { sorted[subKey] = finalConfig[key][subKey] })
        finalConfig[key] = sorted
      })

      this.models = finalConfig.models
      this.schemas = finalConfig.schemas
      this.context = finalConfig.context
      return this
    })
  }
}
