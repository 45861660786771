<template>
  <span
    ref="avatar"
    class="avatar"
    :class="[loaded ? 'avatar-loaded': '', size ? 'avatar-' + size : '']"
  >
    <img
      v-if="user.picture"
      v-track:click="{action:'avatar button', category:'header'}"
      :src="user.picture"
      @load="loaded = true"
    >
    <span class="avatar-initials">{{ user.initials }}</span>
  </span>
</template>

<script>
  import User from '../models/User'

  export default {
    props: {
      user: { type: User, required: true },
      size: String
    },
    data () {
      return {
        loaded: false
      }
    }
  }
</script>
