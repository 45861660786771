export default {
  namespaced: true,
  state: {
    layout: null
  },
  actions: {
    loadWidgets ({ state, rootState, commit }, usedOnly = true) {
      const usedWidgets = state.layout ? state.layout.map(w => w.i) : null
      const widgets = {}
      const promises = []
      Object.keys(rootState.config.modules).forEach(moduleName => {
        const moduleConfig = rootState.config.modules[moduleName]
        const moduleWidgets = moduleConfig.widgets || {}
        Object.keys(moduleWidgets).forEach(key => {
          const moduleWidgetKey = moduleName + '.' + key
          if (!usedOnly || !usedWidgets || usedWidgets.indexOf(moduleWidgetKey) > -1) {
            const raw = moduleWidgets[key]
            widgets[moduleWidgetKey] = null
            promises.push(Promise.resolve(typeof raw === 'function' ? raw() : raw).then(widget => {
              widgets[moduleWidgetKey] = widget.default || widget
            }))
          }
        })
      })
      return Promise.all(promises).then(() => widgets)
    }
  }
}
