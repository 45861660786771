<script>
  import fieldCustomSelect from '../fieldCustomSelect'
  export default {
    extends: fieldCustomSelect,
    watch: {
      selected: function () {
        this.$store.dispatch('sadmin/environments/details', this.selected).then(env => {
          if (env.state !== 'running') {
            this.schema.hint = this.$tc('envNotRunning', env.state, { state: env.state })
          } else {
            this.schema.hint = ''
          }
        })
      }
    }
  }
</script>
