<template>
  <transition name="singlefade">
    <div
      v-if="show"
      :id="item.key"
      class="single-note"
      role="alert"
    >
      <div class="pull-left">
        <span>{{ item.message }}</span>
      </div>
      <div class="pull-right right">
        <!-- This button can be used for certain interaction in the future,
      therefore it is left
      -->
        <!-- <button type="button" :class="'pull-right btn-info'">
      <div class="md-ripple">
        <div class="md-button-content">{{item.buttonText}}</div>
      </div>
    </button> -->

        <span
          class="btn btn-secondary"
          @click="show = !show"
        ><i class="icon-close font-lg" /></span>
      </div>
    </div>
  </transition>
</template>
<script>

  export default {
    name: 'SingleNote',
    props: {
      item: Object
    },
    data () {
      return {
        show: {
          default: true,
          type: Boolean
        }
      }
    }
  }
</script>
<style>
  .single-note {
    padding-left: 8px;
    color: #fff;
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    display:block;
    opacity: 1;
    transition: all ease 0.3s;
  }

  .single-note button {
    float: right;
    margin-right: 20px;
  }

  .single-note {
    display: flex;
    align-items: center;
  }

  .single-note .right {
    margin-left: auto;
    margin-right: 5px;
  }

  .single-note .btn {
    cursor: pointer;
  }

  @media (min-width: 992px) {
    .single-note {
      margin-left: 0;
    }

    .sidebar-minimized .single-note {
      margin-left: 50px;
    }
  }

  .singlefade-enter, .singlefade-leave-active {
    opacity: 0;
  }

  .singlefade-enter-active, .singlefade-leave-active {
    transition: opacity 1s;
  }
</style>
