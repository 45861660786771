import { StringUtil } from '../../../../util'

export default class SadminApplicationInfos {
  constructor ($store) {
    this.$store = $store
    $store.dispatch('sadmin/applications/load')
  }

  watch (applicationId, onUpdate) {
    const findApplication = () => {
      const application = this.$store.state.sadmin.applications.all.find(application => application.id === applicationId)
      if (application) {
        return {
          id: application.id,
          name: application.name,
          route: { name: 'sadmin.' + StringUtil.pluralize(application.type) + '.detail', params: { id: application.id } }
        }
      }
    }
    return this.$store.watch(
      () => `${applicationId}:${(findApplication() || {}).name}`,
      () => onUpdate(findApplication()),
      { immediate: true }
    )
  }
}
