import sadminConfig from './config'
import store from './store'
import StringUtils from '../../util/StringUtil'
import DatahubApplicationInfoPlugin from './plugins/datahub/applicationInfos'

// ATTENTION: HMR did not work when views where loaded from separate directories
const Applications = () => import(/* webpackChunkName: "sadmin" */ './views/Applications.vue')
const Toolbar = () => import(/* webpackChunkName: "sadmin" */ './views/Toolbar.vue')
const ApplicationDetail = () => import(/* webpackChunkName: "sadmin" */ './views/ApplicationDetail.vue')

export default (config) => {
  if (config.env.SYSTEM_TYPES) {
    const types = config.env.SYSTEM_TYPES.split(',').map(StringUtils.pluralize)
    types.forEach(type => {
      if (!Object.prototype.hasOwnProperty.call(sadminConfig.applications.types, type)) {
        console.warn('Unknown system type ' + type)
        sadminConfig.applications.types[type] = { icon: 'icon-chemistry' }
      }
    })
    Object.keys(sadminConfig.applications.types).forEach(type => {
      if (types.indexOf(type) < 0) {
        delete sadminConfig.applications.types[type]
      }
    })
  }

  const children = {}

  Object.keys(sadminConfig.applications.types).forEach((type) => {
    const singularType = StringUtils.singularize(type)
    const props = { default: { type }, toolbar: { type } }

    children[type] = {
      icon: sadminConfig.applications.types[type].icon,
      path: '/' + type,
      sidebar: true,
      condition () {
        return (
          this.$isAllowed('view_applications') ||
          this.$isAllowed('view_environments') // TODO: Remove this check as soon as environments management is moved to a separate place
        ) &&
        this.$store.state.sadmin.systems.find(system => system.type === singularType)
      },
      children: {
        index: {
          props,
          components: {
            default: Applications,
            toolbar: Toolbar
          }
        },
        detail: {
          path: 'detail/:id',
          props: {
            toolbar: { type },
            default: route => ({ id: route.params.id, type })
          },
          components: {
            default: ApplicationDetail,
            toolbar: Toolbar
          }
        },
        create: {
          icon: 'icon-plus',
          props,
          component: 'vfg-form',
          meta: { module: 'sadmin', form: 'application' }
        },
        edit: {
          path: ':id/edit',
          props: {
            ...props,
            default: route => ({ id: route.params.id, type })
          },
          component: 'vfg-form',
          meta: { module: 'sadmin', form: 'application' }
        },
        create_environment: {
          path: 'environments/create',
          icon: 'icon-plus',
          label: 'sadmin.environments.create',
          props,
          component: 'vfg-form',
          meta: { module: 'sadmin', form: 'environment' }
        },
        edit_environment: {
          path: 'environments/:id/edit',
          props: { ...props, default: true },
          label: 'sadmin.environments.edit',
          component: 'vfg-form',
          meta: { module: 'sadmin', form: 'environment' }
        }
      }
    }
  })

  return {
    routes: {
      sadmin: {
        sidebar: true,
        children
      }
    },
    store,
    forms: {
      application: () => import(/* webpackChunkName: "sadmin" */ './forms/application'),
      environment: () => import(/* webpackChunkName: "sadmin" */ './forms/environment')
    },
    widgets: {
      // status: () => import(/* webpackChunkName: "sadmin" */ './widgets/StatusWidget'),
      statusChart: () => import(/* webpackChunkName: "sadmin" */ './widgets/StatusChartWidget'),
      action: () => import(/* webpackChunkName: "sadmin" */ './widgets/ActionButtonWidget'),
      // @Todo: Needs to be hidden here when no accounts available
      costs: () => import(/* webpackChunkName: "sadmin" */ './widgets/CostsWidget'),
      noTeamYet: () => import(/* webpackChunkName: "sadmin" */ './widgets/NoTeamWidget')
    },
    env: {
      APPLICATIONS_SCHEME: ''
    },
    slots: {
      'core:settings/iam/team': [
        () => import(/* webpackChunkName: "sadmin" */ './components/TeamSystemSettings')
      ]
    },
    plugins: {
      datahub: [
        { applicationInfos: DatahubApplicationInfoPlugin }
      ]
    }
  }
}
