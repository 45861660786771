import AssetUtil from '@/util/AssetUtil'

window.dataLayer = window.dataLayer || []
window.gtag = function () { window.dataLayer.push(arguments) }
window.gtag('js', new Date())

function tracking (Vue, { gaId }) {
  if (gaId) {
    init(gaId)
  }
  Vue.mixin({
    methods: {
      track (params) {
        send(params)
      }
    }
  })
  Vue.directive('track', {
    inserted: function (el, binding, vnode) {
      const [event, action, category] = [binding.arg, Object.keys(binding.modifiers)[0], 'general']
      const path = vnode.context.$route.fullPath
      const defaults = { action, category, path }
      el.addEventListener(event, () => {
        const params = { ...defaults, ...(typeof binding.value === 'object' ? binding.value : { label: binding.value }) }
        send(params)
      })
    }
  })
}

function init (gaId) {
  window.gtag('config', gaId)
  AssetUtil.loadJavaScript('https://www.googletagmanager.com/gtag/js?id=' + gaId).catch(
    e => console.error('Tracking Script for GA could not be loaded. Ad/TrackBlocking disabled?')
  )
}

function send (params) {
  window.gtag('event', params.action, {
    event_category: params.category,
    event_label: params.label,
    page_path: params.path
  })
}

export default tracking
