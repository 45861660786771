// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueMoment from 'vue-moment'
import BootstrapVue from 'bootstrap-vue'
import BootstrapAdditions from './components/bootstrap-additions'
import Remote from './remote'
import Router from './router'
import config from './config'
import Modules from './modules'
import Store from './store'
import Lang from './lang'
import { graphql, gql } from './graphql'
import VueFormGenerator from 'vue-form-generator'
import VueCharts from 'vue-chartjs'
import VueApollo from 'vue-apollo'
import VfgCustomElements from './components/vfg-fields'

require('bootstrap-vue/dist/bootstrap-vue.css')
require('./assets/scss/main.scss')

Vue.use(BootstrapVue)
Vue.use(BootstrapAdditions)
Vue.use(VueMoment)
Vue.use(Lang)
Vue.use(VueFormGenerator)
Vue.use(VfgCustomElements)
Vue.use(VueCharts)
Vue.use(VueApollo)

Vue.prototype.$log = function (item) {
  console.log(item)
  return item
}

graphql.query({ query: gql`{env}` })
  .then(
    (remoteEnv) => {
      Object.assign(config.env, remoteEnv.data.env)
      Modules.load(config)
        .then((modules) => {
          Object.keys(modules.modules).forEach((name) => {
            const module = modules.modules[name]
            if (module.store) {
              Store.registerModule(name, module.store)
            }
          })
          config.modules = modules.buildConfig(config)
          config.plugins = modules.buildPlugins()

          Remote.setup(config)

          const store = Store.build(config)
          let nav
          const app = {
            options: {
              el: '#app',
              router: undefined,
              i18n: undefined,
              store,
              apolloProvider: new VueApollo({ defaultClient: graphql }),
              data () {
                return { nav }
              },
              components: {
                BootstrapVue
              }
            },
            Vue,
            buildRouter () {
              if (!app.options.router) {
                app.options.router = Router.build(modules.buildRoutes())
              }
            },
            buildI18n () {
              if (!app.options.i18n) {
                app.options.i18n = new Lang(Object.keys(modules.modules))
              }
            },
            buildNavigation () {
              if (!nav) {
                nav = modules.buildNavigation()
              }
            }
          }

          return store.dispatch('core/initApp', app).then(() => {
            app.buildRouter()
            app.buildI18n()
            app.buildNavigation()
            /* eslint-disable no-new */
            new Vue(app.options)
          })
        })
        .catch((error) => {
          document.getElementById('app').innerText = 'Could not load module' + (error.module ? ' ' + error.module : 's')
          console.error(error)
        })
    },
    (error) => {
      const i18n = new Lang(['core'])
      i18n.setLanguage(Lang.DEFAULT_LANGUAGE)
      new Vue({
        el: '#app',
        template: '<error error="startup"/>',
        i18n,
        router: Router.build([]),
        components: {
          error: () => import('./modules/core/views/Error.vue')
        }
      })
      console.error(error)
    }
  )
