export default class AssetUtil {
  static loadJavaScript (url, async = true) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.onload = resolve
      script.onerror = reject
      script.src = url
      script.setAttribute('async', async ? 'true' : 'false')
      document.documentElement.firstChild.appendChild(script)
    })
  }
}
