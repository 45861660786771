<template>
  <div class="d-none">
    <transition>
      <aside class="aside-menu" ref="aside">
        <slot></slot>
      </aside>
    </transition>
  </div>
</template>

<script>
  const activeSidebars = []
  export default {
    props: {
      contextual: Boolean
    },
    watch: {
      contextual () {
        if (activeSidebars.length && activeSidebars[0] === this) {
          this.setContextualClass()
        }
      }
    },
    mounted () {
      if (!activeSidebars.length) {
        this.$nextTick(() => this.$nextTick(() => document.body.classList.remove('aside-menu-hidden')))
      }
      this.setContextualClass()
      activeSidebars.unshift(this)

      // Reparent the element to app root to avoid css confusion (e.g. by using sidebar from header)
      this.$root.$el.appendChild(this.$refs.aside)
    },
    beforeDestroy () {
      activeSidebars.splice(activeSidebars.indexOf(this), 1)
      if (!activeSidebars.length) {
        document.body.classList.add('aside-menu-hidden')
      }
      if (this.$el) {
        this.$root.$el.removeChild(this.$refs.aside)
      }
    },
    methods: {
      setContextualClass () {
        document.body.classList[this.contextual ? 'add' : 'remove']('aside-menu-contextual')
      }
    }
  }
</script>
