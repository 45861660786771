<template>
  <div class="card-select">
    <div
      v-if="schema.placeholder && !value"
      class="card-select-placeholder"
    >
      {{ schema.placeholder }}
    </div>
    <b-card-group
      deck
      class="card-select-cards"
    >
      <b-card
        v-for="field in schema.values"
        v-show="!schema.readonly || isSelected(field.id)"
        :key="field.id"
        v-track:click="{action:'select', category:'modal form'}"
        :title="field.name || $t(field.id)"
        :img-src="field.img || field.image"
        :img-alt="field.name || $t(field.id)"
        :border-variant="isSelected(field.id) ? (schema.selectedVariant || 'primary') : null"
        class="text-center card-select-card"
        :class="{'card-not-selected' : (!schema.multiple && value !== undefined && !isSelected(field.id)), 'card-select-icon-img': field.icon, 'card-selected': isSelected(field.id), 'card-disabled': schema.disabled || field.disabled , } "
        img-top
        @click="select(field.id)"
      >
        <p class="card-text text-sm-center">
          {{ field.description }}
        </p>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
  import { abstractField } from 'vue-form-generator'

  export default {
    name: 'FieldCardSelect',
    mixins: [abstractField],
    methods: {
      isSelected (id) {
        if (this.schema.multiple) {
          return this.value.indexOf(id) > -1
        }
        return this.value === id
      },
      select (id) {
        if (!this.schema.multiple) {
          this.value = this.isSelected(id) ? undefined : id
        } else if (this.value.indexOf(id) > -1) {
          this.value = this.value.filter(v => v !== id)
        } else {
          this.value = this.value.concat(id)
        }
      },
      formatValueToField (value) {
        if (this.schema.multiple) {
          return (Array.isArray(value) ? value : [value]).filter(id => this.schema.values.find(value => value.id === id))
        }
        return value
      }
    }
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../assets/scss/core/variables";
  @import "~bootstrap/scss/bootstrap";

  .clearfix {
    width: 100%;
    height: 20vh;
  }

  .card {
    background-color: var(--primary-background-color);
    border: 1px solid var(--border-color);
  }
  .card-not-selected {
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .card-not-selected:hover {
    filter: grayscale(0);
    opacity: 1;
  }

  .card-select {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

  }

  .card-select-cards {
    padding: 0 15px 15px;
    justify-content: space-around;
    margin-top: -15px;
    display: flex;
  }

  // .card-select-cards>div:last-child::after {
  //   height: 300px;
  //   content: '';
  //   width: 100%;
  // }

  .card-select-placeholder {
    display: block;
    margin-bottom: 15px;
  }

  .card-select-card {
    flex: 0 0 20%;
    margin-top: 15px;
    cursor: pointer;
    min-width: 15rem;
    .card-body > * {
      pointer-events: none;
    }

    padding: 1px;
    transition: border-color 0.24s;
    &:hover, &.card-selected {
      padding: 0;
      border-width: 2px;
    }
    &.card-disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .card-select-icon-img .card-img-top {
    $padding-x: 2rem;
    $padding-y: 1.25rem;
    width: calc(100% - #{$padding-x * 2});
    margin: $padding-y $padding-x 0;
  }

  @include media-breakpoint-up(sm) {
    .card-deck {
      .card {
        flex: 0 0 20%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .card-select-cards {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
</style>
