import store from './store'
import Vue from 'vue'

export default function () {
  Vue.component('field-datahub-application-json', () => import(/* webpackChunkName: "datahub" */ './components/vfg-fields/datahubApplicationJson.vue'))
  Vue.component('field-datahub-agent-application-json', () => import(/* webpackChunkName: "datahub" */ './components/vfg-fields/datahubApplicationJson.vue'))

  function resolve (route) {
    return { name: route.name, params: { datahubId: this.$store.state.datahub.currentDatahubId } }
  }

  return {
    routes: {
      datahub: {
        path: '/datahub/:datahubId?',
        icon: 'icon-shuffle',
        sidebar: true,
        redirect: '/datahub/bus',
        components: {
          default: () => import(/* webpackChunkName: "datahub" */ './views/Datahub.vue'),
          toolbar: () => import(/* webpackChunkName: "datahub" */ './views/Toolbar.vue')
        },
        children: {
          bus: {
            icon: 'icon-control-end',
            components: {
              default: () => import(/* webpackChunkName: "datahub" */ './views/Bus.vue'),
              toolbar: () => import(/* webpackChunkName: "datahub" */ './views/bus/Toolbar.vue')
            },
            sidebar: true,
            resolve
          },
          explorer: {
            icon: 'icon-map',
            component: () => import(/* webpackChunkName: "datahub" */ './views/Explorer.vue'),
            sidebar: true,
            resolve
          }
        }
      }
    },
    store,
    plugins: {
      sadmin: [
        {
          applicationLinks (links, application) {
            if (application.type === 'datahub') {
              const apiLink = links.find(l => l.type === 'api')
              if (apiLink) {
                apiLink.label = this.$t('datahub.routes.bus._')
                apiLink.url = this.$router.resolve({ name: 'datahub.bus', params: { datahubId: application.id } }).href
                apiLink.icon = 'icon-control-end'
                apiLink.internal = true
              }
            }
          }
        }
      ]
    }
  }
}
