import ApisQuery from './graphql/ApisQuery.gql'

export default {
  namespaced: true,
  wrapActions: {
    'core/initApp': (action, store) => async payload => {
      const authenticated = await action(payload)
      if (authenticated) {
        store.$graphql.query({ query: ApisQuery }).then(res => {
          store.commit('docs/apis', res.data.apis)
        })
      }
      return authenticated
    }
  },
  state: {
    apis: null
  },
  mutations: {
    apis: (state, apis) => {
      state.apis = apis
    }
  }
}
