import store from './store'

export default (config) => ({
  routes: {
    docs: {
      sidebar: true,
      children: {
        howto: {
          icon: 'fa fa-book',
          sidebar: true,
          external: true,
          path: (config.env.DOCUMENTATION_URL || 'https://docs.blugento.com/')
        },
        support: {
          icon: 'fa fa-phone',
          sidebar: true,
          external: true,
          path: (config.env.SUPPORT_URL || 'https://blugento.freshdesk.com')
        },
        api: {
          icon: 'fas fa-code',
          path: '/api-docs/:api?/:tag?/:operation?',
          sidebar: true,
          components: {
            default: () => import(/* webpackChunkName: "swagger" */ './views/SwaggerUI.vue'),
            toolbar: () => import(/* webpackChunkName: "swagger" */ './views/SwaggerUIToolbar.vue')
          },
          condition () {
            return this.$store.state.docs.apis && this.$store.state.docs.apis.length
          }
        },
        playground: {
          icon: 'fa fa-terminal',
          sidebar: true,
          path: '/playground',
          components: {
            default: () => import(/* webpackChunkName: "playground" */ './views/GraphQLPlayground.vue')
          },
          condition () {
            return this.$store.state.core.auth.user.isDev
          }
        }
      }
    }
  },
  store,
  remote: {
    apiDocs: false
  }
})
