export default {
  applications: {
    types: {
      stores: {
        singular: 'store',
        icon: 'icon-basket'
      },
      pims: {
        singular: 'pim',
        icon: 'icon-layers'
      },
      cms: {
        singular: 'cms',
        icon: 'icon-docs'
      },
      datahubs: {
        singular: 'datahub',
        icon: 'icon-organization'
      }
    }
  }
}
