<template>
  <div class="animated fadeIn row">
    <router-view />
    <component
      :is="widget"
      v-for="(widget, i) in widgets"
      :key="'widget-' + i"
      :class="classes(widget)"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    data () {
      return {
        widgets: null
      }
    },
    created () {
      this.$store.dispatch('core/dashboard/loadWidgets', true).then(widgets => {
        this.widgets = widgets
        if (!widgets || (Array.isArray(widgets) && !widgets.length) || !Object.keys(widgets).length) {
          const firstModule = Object.keys(this.$store.state.config.modules).find(module => module !== 'core')
          if (firstModule) {
            this.$router.replace({ name: firstModule })
          }
        }
      })
    },
    computed: {
      ...mapState(['config']),
      layout () {
        if (this.widgets) {
          return Object.values(this.widgets)
        }
        return null
      }
    },
    methods: {
      classes (widget) {
        const classes = ['widget']
        if (widget.props && widget.props.classes) {
          classes.push(widget.props.classes.type)
        } else {
          classes.push('col-md-4')
        }
        return classes
      }
    }
  }
</script>
<style scoped>
  .card {
    border: none;
  }
</style>
