import GraphsQuery from './GraphsQuery.gql'

export default {
  namespaced: true,
  persist: [],
  state: {
    graphs: null,
    currentGraph: null
  },
  mutations: {
    graphs (state, graphs) { state.graphs = graphs }
  },
  actions: {
    load ({ commit, rootState: { datahub: { currentDatahubId: datahubId } } }) {
      return this.$graphql.query({ query: GraphsQuery, variables: { datahubId } }).then(res => {
        commit('graphs', res.data.graphs)
      })
    },
    async get ({ dispatch, state }, graphPath) {
      await dispatch('load')
      const [domain, applicationId, entity, speciality] = graphPath.split('/')
      return state.graphs.find(g =>
        g.domain === domain &&
        g.applicationId === applicationId &&
        g.entity === entity &&
        ((!g.speciality && !speciality) || g.speciality === speciality)
      )
    },
    setCurrentGraph ({ state }, graph) {
      state.currentGraph = graph
    }
  }
}
