<template>
  <router-link
    v-if="url"
    tag="li"
    class="nav-item nav-dropdown open"
    :to="url"
    disabled
  >
    <div
      class="nav-link nav-dropdown-toggle"
      @click="handleClick"
    >
      <i :class="icon" /> {{ $t(name) }}
    </div>
    <ul class="nav-dropdown-items">
      <slot />
    </ul>
  </router-link>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      }
    },
    methods: {
      handleClick (e) {
        e.preventDefault()
        e.target.parentElement.classList.toggle('open')
      }
    }
  }
</script>
