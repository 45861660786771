import jexl from 'jexl'
import extend from 'extend'

export default class Schema {
  constructor (schemas, context, tracker, progress, processor = null) {
    this.schemas = schemas
    this.context = context
    this.tracker = tracker
    this.progress = progress
    this.processor = processor
  }

  compile (recompile) {
    this.tracker.reset()

    const finalSchema = { fields: [], groups: [] }
    const scopes = Object.keys(this.schemas)

    const nextScope = () => {
      const scope = scopes.shift()
      if (!scope) {
        return
      }

      const context = this.context.scope(scope)

      return this._mergeSchemaOverrides(this.schemas[scope], context).then((schema) => {
        const promises = []
        const evaluateFields = (obj) => {
          const fields = []
          const names = []
          if (obj.fields) {
            Object.keys(obj.fields).forEach(name => {
              const field = extend(true, {}, obj.fields[name])
              const model = scope + '.' + name
              field.model = model
              field.onChanged = () => {
                if (this.tracker.wasAccessed(model)) {
                  recompile(this, field)
                }
              }
              names.push(name)
              fields.push(field)
            })
          }
          obj.fields = fields
          fields.forEach((field, i) => promises.push(this._evaluateProperties(field, context).then(
            () => this._postProcessField({ fields, field, scope, name: names[i], context, schema: finalSchema })
          )))
        }

        if (!schema.groups) {
          schema.groups = []
        } else if (!Array.isArray(schema.groups)) {
          schema.groups = Object.values(schema.groups)
        }
        evaluateFields(schema)
        schema.groups.forEach(group => {
          evaluateFields(group)
        })

        return Promise.all(promises).then(() => {
          schema.fields.forEach(field => finalSchema.fields.push(field))
          schema.groups.forEach(group => {
            if (group.fields.length) {
              finalSchema.groups.push(group)
            }
          })
        })
      })
        .then(nextScope)
    }

    return this.progress.promise(nextScope()).then(() => finalSchema)
  }

  _mergeSchemaOverrides (schema, context) {
    const overrides = []
    return Promise.all(
      (schema.overrides || []).map((override, i) => jexl.eval(override.$if, context).then(doOverride => {
        if (doOverride) {
          overrides[i] = { ...override, $if: undefined }
        }
      }))
    ).then(
      () => extend(true, {}, { ...schema, overrides: undefined }, ...overrides)
    )
  }

  _process (type, ...args) {
    if (this.processor && Object.prototype.hasOwnProperty.call(this.processor, type)) {
      return this.progress.promise(this.processor[type](...args))
    }
    return Promise.resolve()
  }

  _postProcessField (props) {
    const { field } = props
    // Set default input type when type is input
    if (field.type === 'input' && !field.inputType) {
      field.inputType = 'text'
    }
    // Add required validator if not present
    if (field.required) {
      if (!Array.isArray(field.validator)) {
        field.validator = field.validator ? [field.validator] : []
      }
      if (field.validator.indexOf('required') < 0) {
        field.validator.unshift('required')
      }
    }
    // Normalize select options so that they always are objects with id and name
    if (['select', 'radios', 'checklist', 'vueMultiSelect'].indexOf(field.type) > -1) {
      if (!field.values) {
        field.values = []
      } else {
        let idField = 'id'
        let nameField = 'name'
        let stateField = 'state'
        if (field.selectOptions) {
          idField = field.selectOptions.id || idField
          nameField = field.selectOptions.name || idField
          stateField = field.selectOptions.state || stateField
          delete field.selectOptions.id
          delete field.selectOptions.name
          delete field.selectOptions.state
        }
        field.values = field.values.map(option => {
          if (typeof option === 'object') {
            return { id: option[idField], name: option[nameField], state: option[stateField] }
          } else {
            return { id: option, name: option, state: option }
          }
        })
      }
    }
    return this._process('processField', props)
  }

  _evaluateProperties (field, context) {
    const promises = []
    function evaluateProperties (obj, path) {
      Object.keys(obj).forEach(key => {
        const currentPath = path + '.' + key
        if (key[0] === '$') {
          const targetKey = key.substr(1)
          const expression = obj[key]
          delete obj[key]
          if (typeof expression === 'string' && expression) {
            promises.push(jexl.eval(expression, context).then(res => {
              if (res === undefined) {
                console.warn('Expression on ' + currentPath + ' returned undefined - this might cause unwanted behavior')
              }
              obj[targetKey] = res
            }))
          }
        }
        if (typeof obj[key] === 'object') {
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item, i) => evaluateProperties(item, `${currentPath}[${i}]`))
          } else {
            evaluateProperties(obj[key], currentPath)
          }
        }
      })
    }
    evaluateProperties(field, field.model)
    return Promise.all(promises)
  }
}
