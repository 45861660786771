export default (Vue) => {
  // Global Array for notifications
  Vue.prototype.$notifications = []
  // Register function, that is usable anywhere in the Vue instance
  Vue.prototype.$pushNote = (message, type = 'info', buttonText = 'Ok') => {
    if (Vue.prototype.$notifications) {
      Vue.prototype.$notifications.push({ message, type, buttonText })
    }
  }
}
