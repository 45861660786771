import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const persistPaths = ['lang']
const modules = {}

const actionWrappers = {}
class MyStore extends Vuex.Store {
  $set = Vue.set
  $delete = Vue.delete

  constructor (options) {
    const extractActionWrappers = (opts) => {
      if (opts.wrapActions) {
        Object.keys(opts.wrapActions).forEach(action => {
          if (!actionWrappers[action]) {
            actionWrappers[action] = []
          }
          actionWrappers[action].push(opts.wrapActions[action])
        })
      }
      if (opts.modules) {
        Object.keys(opts.modules).forEach(name => extractActionWrappers(opts.modules[name]))
      }
    }
    extractActionWrappers(options)
    super(options)
  }

  dispatch (type, payload) {
    if (!Object.prototype.hasOwnProperty.call(actionWrappers, type)) {
      return Promise.resolve(super.dispatch(type, payload))
    }
    let originalResult
    let wrapped = () => {
      originalResult = Promise.resolve(super.dispatch(type, payload))
      return originalResult
    }
    actionWrappers[type].forEach(wrapper => {
      const callback = wrapper(wrapped, this)
      wrapped = (payload) => {
        const ret = callback(payload)
        return ret && typeof ret.then === 'function' ? ret : Promise.resolve(ret)
      }
    })
    return wrapped(payload).then(() => originalResult)
  }
}

export default class Store {
  static registerModule (namespace, module) {
    const addPaths = (path, config) => {
      if (config.persist) {
        config.persist.forEach((key) => {
          persistPaths.push(path + '.' + key)
        })
      }
      if (config.modules) {
        Object.keys(config.modules).forEach((name) => addPaths(path + '.' + name, config.modules[name]))
      }
    }
    addPaths(namespace, module)
    modules[namespace] = module
  }

  static build (config) {
    const plugins = []
    const debug = process.env.NODE_ENV !== 'production'
    if (persistPaths.length) {
      plugins.push(createPersistedState({ paths: persistPaths }))
    }
    if (debug) {
      plugins.push(createLogger())
      plugins.push(function actionLoggerPlugin (store) {
        store.subscribeAction(({ type, payload }, state) => {
          console.log(`Dispatched "${type}"`)
        })
      })
    }

    return new MyStore({
      strict: debug,
      plugins,
      state: {
        config,
        lang: undefined
      },
      getters: {
        modules: (state) => {
          return state.config.env.MODULES.split(',')
        }
      },
      modules
    })
  }
}
