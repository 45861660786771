import ApiClient from './util/ApiClient'
import axios from 'axios'
import UriUtil from './util/UriUtil'
import Vue from 'vue'
import Vuex from 'vuex'

const tokenProviders = {}
const tokenProviderAwaiters = {}

axios.interceptors.request.use(config => {
  return Remote.getToken(config.url).then(token => {
    if (token) {
      config.headers.common.Authorization = token
    }
    return config
  })
})

export default class Remote {
  static setup (config) {
    const baseURL = UriUtil.resolve(document.location.origin, config.env.API_URL)
    Object.keys(config.modules).forEach((moduleName) => {
      const remote = config.modules[moduleName].remote || {}
      const env = config.modules[moduleName].env || {}

      createApiClient(this, moduleName, env, remote, baseURL)
    })
  }

  static setTokenProvider (baseUrl, tokenProvider) {
    Object.keys(tokenProviders).forEach(base => {
      if (base.substr(0, baseUrl.length) === baseUrl || baseUrl.substr(0, base.length) === base) {
        delete tokenProviders[base]
      }
    })
    tokenProviders[baseUrl] = tokenProvider
    Object.keys(tokenProviderAwaiters).filter(url => url.substr(0, baseUrl.length) === baseUrl).forEach(url => {
      tokenProviderAwaiters[url].forEach(awaiter => awaiter(tokenProvider))
      delete tokenProviderAwaiters[url]
    })
  }

  static async getToken (url, awaitProvider = false) {
    const baseUrl = Object.keys(tokenProviders).find(baseUrl => url.substr(0, baseUrl.length) === baseUrl)
    if (!baseUrl && awaitProvider) {
      return new Promise(resolve => {
        if (!tokenProviderAwaiters[url]) {
          tokenProviderAwaiters[url] = []
        }
        tokenProviderAwaiters[url].push(tokenProvider => tokenProvider().then(resolve))
      })
    }
    return Promise.resolve(baseUrl ? tokenProviders[baseUrl]() : null)
  }
}

function createApiClient (target, name, env, remote, parentBaseURL, parentEnvPrefix = null) {
  let client
  Object.defineProperty(target, name, {
    get: () => {
      if (!client) {
        const envPrefix = parentEnvPrefix === null ? '' : parentEnvPrefix + name.toUpperCase() + '_'
        const baseURL = UriUtil.resolve(parentBaseURL,
          remote.baseURL ||
          env[envPrefix + 'API_URL'] ||
          (Object.prototype.hasOwnProperty.call(remote, 'path') ? remote.path : name)
        )
        client = new ApiClient(baseURL, remote.apiDocs === false ? undefined : (remote.apiDocs || 'api-docs'))
        if (remote.children) {
          Object.keys(remote.children).forEach(
            api => createApiClient(client, api, env, remote.children[api], baseURL, envPrefix)
          )
        }
      }
      return client
    }
  })
}

Vue.prototype.$remote = Remote
Vuex.Store.prototype.$remote = Remote
