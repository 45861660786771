<template>
  <div
    v-if="items"
    id="notifications"
    class="notifications"
  >
    <SingleNote
      v-for="item in items"
      :key="item.key"
      :item="item"
      :class="item.type"
    />
  </div>
</template>
<script>
  import SingleNote from './NotificationsSingleNote'

  export default {
    name: 'Notifications',
    components: {
      SingleNote
    },
    data () {
      // In order to get any notifications items are
      // globally stored and returned here
      return {
        items: this.$notifications
      }
    }
  }
</script>
<style>
  .notifications {
    margin-left: 230px;
    transition: all ease 0.3s;
    position: fixed;
    padding-bottom: 0;
    height: auto;
    width: calc(100% - 230px);
    bottom: 0;
    left: 0;
  }

  .sidebar-minimized .notifications {
    margin-left: 0;
    width: 100%;
  }

   @media (max-width: 992px) {
    .notifications {
      margin-left: 0;
      width: 100%;
    }
   }
</style>
