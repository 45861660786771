export default {
  namespaced: true,
  persist: ['darkMode'],
  state: {
    darkMode: undefined
  },
  mutations: {
    set (state, obj) {
      state[obj.setting] = obj.value
    }
  }
}
