import extend from 'extend'
import Progress from './lib/Progress'
import Config from './lib/Config'
import Context from './lib/Context'
import Tracker from './lib/Tracker'
import Schema from './lib/Schema'
import Control from './lib/Control'

const defaultRecompile = () => console.error('Refusing to recompile without being compiled first')

export default class VfgCfg {
  constructor (options = {}) {
    this.progress = new Progress()
    this.schema = null
    this.allFields = []
    this.model = null
    this.context = null
    this.ready = false
    this.processor = options.processor
    this.recompile = defaultRecompile
    this.control = new Control(this, options)
  }

  static createCompoundProcessor (processors, bindable = null) {
    const processorFunctions = {}
    const compoundProcessor = {}
    processors.forEach(processor => {
      Object.keys(processor).filter(fn => typeof processor[fn] === 'function' && fn.substr(0, 7) === 'process').forEach(fn => {
        if (!Object.prototype.hasOwnProperty.call(processorFunctions, fn)) {
          processorFunctions[fn] = []
          compoundProcessor[fn] = (...args) => Promise.all(processorFunctions[fn].map(p => p(...args)))
        }
        processorFunctions[fn].push(processor[fn].bind(bindable || processor))
      })
    })
    return compoundProcessor
  }

  compile (rawConfig, callback) {
    const progress = this.progress
    this.ready = false
    this.recompile = defaultRecompile
    progress.reset()

    if (!rawConfig) {
      progress.total = 1
      return
    }

    const config = new Config(progress)
    config.load(rawConfig).then(
      ({ models, schemas, context: rawContext }) => {
        const model = extend(true, {}, models)
        const tracker = new Tracker()
        const context = new Context(schemas, model, rawContext, progress, tracker)
        const schema = new Schema(schemas, context, tracker, progress, this.processor)

        const compile = () => {
          this.ready = false
          return schema.compile(compile).then(
            compiled => {
              // this.schema = compiled
              this.allFields = [compiled, ...compiled.groups].reduce(
                (allFields, schema) => allFields.concat(schema.fields), []
              )
              this.control.setSchema(compiled)
              this.model = model
              this.context = rawContext
              this.ready = true
              this.recompile = compile
              this.fixInvalidModel()
              callback(null, this)
            },
            error => { callback(error, this) }
          )
        }

        return compile()
      },
      error => { callback(error, this) }
    )
  }

  getModelValue (field) {
    if (!field.model) {
      return undefined
    }
    const path = field.model.split('.')
    let obj = this.model
    while (path.length) {
      const part = path.shift()
      if (!obj || !Object.prototype.hasOwnProperty.call(obj, part)) {
        return undefined
      }
      obj = obj[part]
    }
    return obj
  }

  clearModelValue (field) {
    if (!field.model) {
      return
    }
    const path = field.model.split('.')
    const last = path.pop()
    let obj = this.model
    while (path.length) {
      const part = path.shift()
      if (!Object.prototype.hasOwnProperty.call(obj, part) || !obj[part]) {
        return
      }
      obj = obj[part]
    }
    obj[last] = undefined
  }

  fixInvalidModel () {
    this.allFields.filter(field => {
      if (['select', 'radios', 'checklist', 'vueMultiSelect'].indexOf(field.type) > -1) {
        const values = field.values.map(option => option.id)
        const value = this.getModelValue(field)
        if (field.multiple) {
          if (!Array.isArray(value)) {
            return true
          }
          let i = value.length
          while (i--) {
            if (values.indexOf(value[i]) < 0) {
              value.splice(i, 1)
            }
          }
          return false
        }
        return value === undefined || values.indexOf(value) < 0
      }
    }).forEach(field => this.clearModelValue(field))
  }

  getSanitizedModel () {
    const model = {}
    this.allFields.forEach(field => {
      const value = this.getModelValue(field)
      if (value === undefined) {
        return
      }
      const path = field.model.split('.')
      const last = path.pop()
      let parent = model
      path.forEach(part => {
        if (!Object.prototype.hasOwnProperty.call(parent, part)) {
          parent[part] = {}
        }
        parent = parent[part]
      })
      parent[last] = value
    })
    return model
  }
}
