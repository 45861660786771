import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const defaultRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'home'
  }
]

export default {
  /**
   * @param {Array} moduleRoutes
   * @returns {VueRouter}
   */
  build (moduleRoutes) {
    const routes = defaultRoutes.slice(0)
    moduleRoutes.forEach(route => {
      routes.push(route)
    })
    return new Router({
      mode: 'hash',
      linkActiveClass: 'open active',
      scrollBehavior: () => ({ y: 0 }),
      routes
    })
  }
}
