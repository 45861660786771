<template>
  <div
    v-if="cfg"
    class="position-relative h-100 d-flex vfg-container pb-5"
  >
    <div
      v-if="cfg.control.steps.length > 1"
      class="mb-3"
    >
      {{ $t('form.steps', { step: step, steps: cfg.control.steps.length}) }}
    </div>
    <component
      :is="formComponents.header"
      v-if="formComponents.header"
      :cfg="cfg"
      @systemSelected="setSystem"
    />
    <h5 v-else>
      {{ $t($route.meta.label) }}
    </h5>

    <custom-progress
      :current="cfg && cfg.progress && cfg.progress.current || 0"
      :total="cfg && cfg.progress && cfg.progress.total || 0"
    />

    <div
      v-if="compilationError"
      class="alert alert-danger"
    >
      Oh snap, an error occurred while generating the form
    </div>

    <template v-else-if="cfg">
      <template>
        <div
          v-if="cfg && cfg.model && cfg.model.application && cfg.control.hasPrevious"
          class="mb-3 pt-3 pb-5 col-md-6 col-sm-12 float-left card"
        >
          <div class="card-header ">
            <img
              v-if="system && system.type"
              class="col-6"
              :src="system.img || system.image"
            >
            <span class="col-6 align-top">{{ system.name }}</span>
            <p class="col-12 mt-3">
              {{ system.description }}
            </p>
            <span
              v-if="isDemoStore"
              class="col-12 text-danger small"
            >{{ isDemoStore }}</span>
          </div>
          <div class="card-body">
            <div class="font-weight-bold">
              Name
            </div>
            <span>{{ cfg.model.application.name || $t('form.setNameFirst') }}</span>
            <div class="font-weight-bold mt-3">
              Environment
            </div>
            <div class="mb-4">
              <span v-if="information.environment.type">{{ information.environment.label + ' (' + information.environment.type + ', ' + information.environment.state + ')' }}</span>
              <span v-else>{{ $t('plsSelect', {type: 'an environment'}) }}</span>
            </div>
            <!-- Datahub information for this form will be added in the future -->
            <!-- <div v-if="cfg.model.application.datahub && cfg.model.application.type !== 'pim'">
              <h4>Datahub</h4>
              <h5 class="pl-3">{{information.datahub.label || $t('plsSelect', {type: 'a datahub'})}}</h5>
            </div> -->
          </div>
        </div>
      </template>

      <b-form
        :validated="cfg.control.validated"
        :class="formCol + ' col-sm-12 vfg-form pb-5 float-right  pl-3'"
        @submit.prevent.stop="submit()"
      >
        <vue-form-generator
          v-if="cfg.schema && cfg.model"
          ref="vfg"
          :model="cfg.model"
          :schema="cfg.schema"
          @model-updated="formUpdate"
        />
      </b-form>

      <div class="form-footer pt-5">
        <div
          v-if="savingError"
          class="col-12 alert alert-danger"
        >
          {{ errorMsg }}
        </div>
        <b-button
          v-if="!cfg.control.hasPrevious"
          v-track:click="{action: 'cancel', category: this.$attrs.type + ' form'}"
          :disabled="saving"
          variant="outline-secondary"
          raised
          class="mr-auto float-left"
          @click="back"
        >
          {{ $t('btn.cancel') }}
        </b-button>
        <b-button
          v-if="cfg.control.hasPrevious"
          v-track:click="{action: 'previous', category: this.$attrs.type + ' form'}"
          class="float-left"
          variant="outline-primary"
          icon="fa fa-angle-left"
          @click="cfg.control.toPreviousStep()"
        >
          {{ $t('btn.previous') }}
        </b-button>
        <b-button
          v-if="cfg.control.hasNext"
          v-track:click="{action: 'continue', category: this.$attrs.type + ' form'}"
          :disabled="!(cfg && cfg.model && cfg.model.application.system)"
          class="float-right"
          raised
          icon="fa fa-angle-right"
          @click="cfg.control.toNextStep($refs.vfg)"
        >
          {{ $t('btn.continue') }}
        </b-button>

        <template v-if="!cfg.control.hasNext">
          <component
            :is="formComponents.saveButton"
            v-if="formComponents.saveButton"
            class="float-right"
            :cfg="cfg"
            :form="this"
          />
          <b-button
            v-else
            class="float-right"
            :disabled="!canSave"
            :icon="saving || !cfg.ready ? 'fa fa-circle-notch fa-spin' : 'fa fa-check'"
            raised
            variant="primary"
            @click="submit()"
          >
            {{ cfg.ready ? $t('btn.save') : '' }}
          </b-button>
        </template>
        <div class="overlay-bg">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import VfgCfg from './vfg-cfg'
  import CustomProgress from './Progress'
  import { routeBackMixin } from '../mixins/routeBackMixin'

  export default {
    components: { CustomProgress },
    mixins: [routeBackMixin],
    inheritAttrs: false,
    data () {
      return {
        compilationError: false,
        savingError: false,
        errorMsg: null,
        validated: false,
        title: null,
        saving: false,
        cfg: null,
        forms: [],
        steps: [],
        system: null,
        information: {
          environment: {},
          datahub: {}
        },
        formOptions: {
          validateAfterChanged: true
        }
      }
    },
    computed: {
      isDemoStore () {
        if (this.$store.state.core.auth.user.isSandboxUser !== null) {
          return this.$t('form.demoStore', { days: this.$store.state.config.env.SANDBOX_TIME_LIMIT })
        } else {
          return false
        }
      },
      step () {
        if (this.cfg) {
          return this.cfg.control.getCurrentStepIndex() + 1
        } else {
          return null
        }
      },
      formCol () {
        return this.cfg.control.hasPrevious ? 'col-md-6' : 'col-md-12'
      },
      canSave () {
        if (this.saving || !this.cfg || !this.cfg.ready || !this.cfg.schema) {
          return false
        }
        const allFields = this.cfg.allFields.filter(field =>
          field.visible !== false &&
          field.disabled !== true &&
          field.type !== 'label' &&
          field.type !== 'alert'
        )
        if (!allFields.length) {
          return false
        }
        return allFields.every(field => {
          return !field.required || !this.isUnselectable(field)
        })
      },
      formComponents () {
        const components = {}
        this.forms.forEach(form => {
          if (form.components) {
            const formComponents = form.components.call(this, this.cfg.model)
            if (typeof formComponents === 'object') {
              Object.assign(components, formComponents)
            }
          }
        })
        return components
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          this.validated = false

          this.compilationError = false
          let error = true

          const { module: moduleName, form: formName } = route.meta
          const moduleConfig = this.$store.state.config.modules
          if (!moduleName || !formName) {
            console.error('module and form are required on $route.meta')
          } else if (!moduleConfig[moduleName]) {
            console.error('Unknown module ' + moduleName)
          } else if (!moduleConfig[moduleName].forms || !moduleConfig[moduleName].forms[formName]) {
            console.error(`Unknown form ${formName} on module ${moduleName}`)
          } else {
            error = false
          }
          if (error) {
            this.compilationError = true
            return
          }
          const includes = new Array(moduleConfig[moduleName].forms[formName])
          this.$store.state.config.plugins[moduleName].forEach(plugin => {
            if (plugin.forms && plugin.forms[formName]) {
              includes.push(plugin.forms[formName])
            }
          })
          Promise.all(
            includes.map(include => typeof include === 'function' ? include().then(i => i.default) : include)
          ).then(
            (forms) => {
              this.forms = forms
              this.cfg = new VfgCfg({ processor: VfgCfg.createCompoundProcessor([...forms, this], this) })
              this.cfg.compile(forms.map(form => form.getConfig.call(this)), (error) => {
                if (error) {
                  console.error(error)
                  this.compilationError = true
                }
              })
            }
          ).catch(
            (error) => {
              console.error(error)
              this.compilationError = true
            }
          )
        }
      }
    },
    methods: {
      formUpdate () {
        if (this.cfg.model.application && this.cfg.model.application.environment_id) {
          this.applicationEnvironment(this.cfg.model.application.environment_id)
        }
      },
      applicationEnvironment (id) {
        this.information.environment = this.$store.state.sadmin.environments.all.find(env => env.id === id)
      },
      setSystem (system) {
        this.system = system
      },
      isUnselectable (field) {
        if (['select', 'checklist', 'radio'].indexOf(field.type) > -1) {
          return !field.values || !field.values.length
        }
        return false
      },
      processField ({ field }) {
        if (field.type === 'select') {
          field.type = 'customSelect'
          if (!field.selectOptions) {
            field.selectOptions = {}
          }
          if (!field.selectOptions.noneSelectedText) {
            field.selectOptions.noneSelectedText = this.$t('plsSelect', { type: '' })
          }
        }
      },
      submit () {
        if (!this.cfg.ready || this.saving || !this.$refs.vfg) {
          return
        }
        this.cfg.control.validate(this.$refs.vfg).then(errors => {
          if (errors.length) {
            return
          }
          this.saving = true
          this.savingError = false
          let promise = Promise.resolve()
          this.forms.forEach(form => {
            if (form.save) {
              promise = promise.then(() => form.save.call(this, this.cfg.getSanitizedModel()))
            }
          })
          promise.then(
            () => {
              this.saving = false
              this.back()
            },
            (error) => {
              console.error(error)
              this.saving = false
              this.savingError = true
              this.errorMsg = error.message
            }
          )
        })
      },
      onClose () {
        const closingForm = this.forms.find(form => form.close)
        if (closingForm) {
          closingForm.close.call(this)
        } else {
          this.$router.replace('/')
        }
      },
      createReloadButton () {
        return {
          classes: 'btn btn-outline-secondary fa fa-sync fa-sm',
          onclick: (m, f, event) => {
            event.preventDefault()
            event.target.classList.add('fa-spin')
            event.target.disabled = true
            this.cfg.recompile().then(() => {
              event.target.classList.remove('fa-spin')
              event.target.disabled = false
            })
          }
        }
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../assets/scss/core/variables";
  @import "~bootstrap/scss/bootstrap";

  .card {
    background-color: var(--secondary-background-color);
  }

  .card-header {
    background-color: var(--secondary-background-color);
  }

  .card-select-placeholder {
    text-align: left;
  }

  .vfg-container {
    flex-wrap: wrap;
    align-content: start;
  }

  .vfg-form {
    flex-direction: column;
    .custom-progress {
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
    }
  }

  .form-footer {
    background:  linear-gradient(to top, var(--primary-background-color) 50%, transparent);
    width: inherit;
    position: fixed;
    bottom:0;
    width: auto;
    left:0;
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
    transition: all ease 0.2s;
    padding-top: 10px;
    padding-bottom: 10px;
  }

@include media-breakpoint-up(lg) {
    .sidebar-fixed .form-footer {
      margin-left: 230px;
      width: calc(100% - 230px);
    }
}

@include media-breakpoint-down(lg) {
  .form-footer {
    margin-left: 0;
    width: 100%;
  }

   .sidebar-minimized .form-footer {
      margin-left: 50px;
      width: calc(100% - 50px);
  }
}
</style>
