import Vue from 'vue'
import core from './core'
import datahub from './datahub'
import docs from './docs'
import sadmin from './sadmin'

const availableModules = { core, datahub, docs, sadmin }

const defaultComponent = Vue.component('default-router-view', {
  template: '<router-view></router-view>'
})
const defaultToolbarComponent = Vue.component('default-router-toolbar-view', {
  render: (createElement) => createElement('router-view', { props: { name: 'toolbar' } })
})

function getModuleEnv (name, config, moduleNames) {
  const env = {}
  if (name === 'core') {
    const excludePrefixes = moduleNames.filter(n => n !== 'core').map(n => n.toUpperCase() + '_')
    Object.keys(config.env).filter(k => !excludePrefixes.find(p => k.substr(0, p.length) === p)).forEach(key => {
      env[key] = config.env[key]
    })
  } else {
    const envPrefix = name.toUpperCase() + '_'
    const envPrefixLength = envPrefix.length
    Object.keys(config.env).forEach(key => {
      if (key.substr(0, envPrefixLength) === envPrefix) {
        env[key.substr(envPrefixLength)] = config.env[key]
      }
    })
  }
  return env
}

export default class Modules {
  constructor (modules) {
    this.modules = modules
    Object.keys(modules).forEach((module) => {
      const prepareRouteChildren = (children, parentPath) => {
        Object.keys(children).forEach((key) => {
          const child = children[key]
          const path = (parentPath ? parentPath + '.' : '') + key
          child.name = path
          if (!child.meta) {
            child.meta = {}
          }
          if (!child.meta.label) {
            if (child.label) {
              child.meta.label = child.label
            } else {
              const pathParts = path.split('.')
              pathParts.shift()
              if (module === 'core') {
                pathParts.unshift(key)
              }
              pathParts.push('_')
              child.meta.label = module + '.routes.' + pathParts.join('.')
            }
          }
          if (child.children) {
            prepareRouteChildren(child.children, path)
          }
        })
      }
      prepareRouteChildren(modules[module].routes)
    })
  }

  static async load (config) {
    const names = ['core'].concat(config.env.MODULES.split(',').filter(m => m !== 'core'))
    const modules = {}
    for (const name of names) {
      if (!Object.prototype.hasOwnProperty.call(availableModules, name)) {
        console.error(`Unknown module '${name}'`)
      } else {
        const module = availableModules[name]
        modules[name] = typeof module === 'function' ? (await module({ env: getModuleEnv(name, config, names) })) : module
      }
    }
    return new Modules(modules)
  }

  buildConfig (config) {
    const moduleConfig = {}
    Object.keys(this.modules).forEach((name) => {
      moduleConfig[name] = { env: {} }
      const mod = this.modules[name]
      Object.keys(mod).filter((key) => ['routes', 'store', 'plugins'].indexOf(key) < 0).forEach((key) => {
        moduleConfig[name][key] = mod[key]
      })
      moduleConfig[name].env = Object.assign(moduleConfig[name].env || {}, getModuleEnv(name, config, Object.keys(this.modules)))
    })
    return moduleConfig
  }

  buildPlugins () {
    const plugins = {}
    Object.keys(this.modules).forEach((name) => {
      plugins[name] = []
    })
    Object.keys(this.modules).forEach((fromName) => {
      const mod = this.modules[fromName]
      if (mod.plugins) {
        Object.keys(mod.plugins).forEach(toName => {
          const modPlugins = Array.isArray(mod.plugins[toName]) ? mod.plugins[toName] : [mod.plugins[toName]]
          if (!this.modules[toName]) {
            console.debug(`Module ${fromName} has plugin${modPlugins.length > 1 ? 's' : ''} for module ${toName} which is not active`)
          } else {
            plugins[toName].push(...modPlugins)
          }
        })
      }
    })
    return plugins
  }

  buildNavigation () {
    const nav = []
    const addEntries = (parentPath, parent, routes, isLast) => {
      Object.keys(routes).forEach((path) => {
        const route = routes[path]
        if (route.sidebar) {
          const navItem = {
            name: route.meta.label,
            icon: route.icon,
            url: route.resolve ? function () { return route.resolve.call(this, route) } : { name: route.name, path: route.path },
            condition: route.condition
          }
          parent.push(navItem)
          if (route.children && Object.values(route.children).filter(c => c.sidebar).length) {
            if (parentPath) {
              navItem.children = []
              addEntries(parentPath + '/' + path, navItem.children, route.children)
            } else {
              navItem.title = true
              addEntries('/' + path, parent, route.children)
              parent.push({ divider: true })
            }
          }
        }
      })
    }
    const routes = []
    const lastRoutes = []
    Object.keys(this.modules).forEach(name => {
      const moduleRoutes = {}
      const moduleLastRoutes = {}
      Object.keys(this.modules[name].routes).forEach(key => {
        const route = this.modules[name].routes[key]
        if (route.last) {
          moduleLastRoutes[key] = route
        } else {
          moduleRoutes[key] = route
        }
      })
      ;[{ i: moduleRoutes, t: routes }, { i: moduleLastRoutes, t: lastRoutes }]
        .filter(({ i }) => Object.keys(i).length)
        .forEach(({ i, t }) => t.push(i))
    })
    const size = routes.length + lastRoutes.length
    routes.concat(lastRoutes).forEach((routes, i) => {
      addEntries('', nav, routes, i === size - 1)
    })
    return nav
  }

  buildRoutes () {
    const allRoutes = {}
    const addRoutes = (routes, target, parent, parentPath) => {
      Object.keys(routes).forEach((path) => {
        const route = Object.assign({}, { path: path === 'index' && parent ? '' : (parent ? '' : '/') + path }, routes[path])

        let components = route.components
        if (route.component) {
          if (!components) {
            components = {}
          }
          components.default = route.component
        }
        delete route.component
        delete route.components

        const children = route.children && Object.keys(route.children).length ? route.children : undefined
        delete route.children

        const fullPath = (parentPath || '') + '/' + path
        if (!Object.prototype.hasOwnProperty.call(allRoutes, fullPath)) {
          allRoutes[fullPath] = {}
          target.push(allRoutes[fullPath])
        }
        const targetRoute = Object.assign(allRoutes[fullPath], route)
        if (components) {
          targetRoute.components = Object.assign(targetRoute.components || {}, components)
        }
        if (children) {
          if (!targetRoute.children) {
            targetRoute.children = []
          }
          addRoutes(children, targetRoute.children, targetRoute, fullPath)
        }
      })
    }

    const answer = []
    Object.keys(this.modules).forEach((module) => addRoutes(this.modules[module].routes, answer))

    function hasChildrenWithToolbars (route) {
      return route.children && route.children.some(c => c.components && c.components.toolbar)
    }
    const fixMissingComponents = function (route, parent) {
      if (!route.components) {
        route.components = {}
      } else {
        Object.keys(route.components).forEach(key => {
          if (typeof route.components[key] === 'string') {
            route.components[key] = Vue.component(route.components[key])
          }
        })
      }
      if (!route.components.default) {
        if (route.children && route.children.length && !route.children.find(c => c.index)) {
          route.redirect = { name: route.children[0].name }
        }
        route.components.default = defaultComponent
      }
      if (!route.components.toolbar && parent && hasChildrenWithToolbars(parent)) {
        // When one child has a toolbar all its siblings have to have one
        route.components.toolbar = defaultToolbarComponent
      }
      if ((route.components.toolbar || hasChildrenWithToolbars(route)) && parent && !parent.components.toolbar) {
        parent.components.toolbar = defaultToolbarComponent
      }
      if (route.children) {
        route.children.forEach(child => fixMissingComponents(child, route))
      }
    }
    answer.forEach(route => fixMissingComponents(route))
    return answer
  }
}
